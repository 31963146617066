import { Contract } from "ethers";
import React from "react";

import useWallet from "hooks/useWallet";
import { getMuteFactory, getMuteRouter } from "utils/ethereum/contracts";

export const useMuteRouterContract = () => {
  const { chainId, provider } = useWallet();

  const useMuteRouter = React.useMemo(() => {
    const contractInfo = getMuteRouter(chainId);
    if (!contractInfo.address) {
      return null;
    }
    const smc = new Contract(contractInfo.address, contractInfo.abi, provider);
    return smc;
  }, [chainId, provider]);

  return { useMuteRouter };
};

export const useMuteFactoryContract = () => {
  const { chainId, provider } = useWallet();

  const useMuteFactory = React.useMemo(() => {
    const contractInfo = getMuteFactory(chainId);
    if (!contractInfo.address) {
      return null;
    }
    const smc = new Contract(contractInfo.address, contractInfo.abi, provider);
    return smc;
  }, [chainId, provider]);

  return { useMuteFactory };
};
