import useWallet from "hooks/useWallet";
import { useQuery } from "react-query";

import { useAtomicRepaymentContractAction } from "hooks/contract/core/useAtomicRepaymentContract";
import { usePriceContract } from "hooks/contract/core/usePriceContract";
import { getDataToUpdatePrice } from "utils/ethereum/getDataToUpdatePrice";

export const useGetTotalOutstanding = (prjToken, lendingToken) => {
  const { account, chainId } = useWallet();
  const { PriceContract } = usePriceContract();

  const { atomicRepaymentContract } = useAtomicRepaymentContractAction();
  const remainingDeposit = useQuery(
    ["total-outstanding", account, chainId, prjToken, lendingToken],
    async () => {
      const { priceIds, payableAmount, updateData } = await getDataToUpdatePrice(
        [prjToken, lendingToken],
        PriceContract
      );

      const res = await atomicRepaymentContract.callStatic.getTotalOutstandingWithUpdatePrices(
        account,
        prjToken,
        lendingToken,
        priceIds,
        updateData,
        { value: payableAmount }
      );

      return res;
    }
  );

  return remainingDeposit.data;
};
