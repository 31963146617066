/* eslint-disable no-underscore-dangle */
import { ApolloClient, InMemoryCache, defaultDataIdFromObject } from "@apollo/client";
import { CHAIN_ID } from "app/constants";
import { GraphByChainId } from "constants/graphUrl";

export const getClient = (chainId = CHAIN_ID) =>
  new ApolloClient({
    uri: GraphByChainId[chainId] || GraphByChainId[Object.keys(GraphByChainId)[0]],
    cache: new InMemoryCache({
      dataIdFromObject(responseObject) {
        switch (responseObject.__typename) {
          case "LendingToken":
            return `LendingToken:${responseObject.address}`;
          case "ProjectToken":
            return `ProjectToken:${responseObject.address}`;
          case "TotalState":
            return `TotalState:${responseObject.type}:${responseObject.lendingTokenAddress}`;
          default:
            return defaultDataIdFromObject(responseObject);
        }
      },
    }),
  });
