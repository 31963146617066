/* eslint-disable no-await-in-loop */
import { BigNumber, Contract } from "ethers";
import { useWallet } from "hooks";
import { useMultiCallContractInstance } from "hooks/contract/multicall/useMultiCallContract";
import { useCheckLeveragePositions, useGetLeverageTypes } from "hooks/contract/useLeverageContract";
import { get, groupBy, map, omit } from "lodash";
import { useQuery } from "react-query";
import { MainNetworkSupported, isWrapNative, wrapNativeToken } from "utils/addressUtils";
import { ERC20TokenABI } from "utils/ethereum/abi";
import {
  getPriceProviderAggregatorContract,
  getPrimaryIndexTokenZksync,
  getPythProvider,
  getUniswapV2Factory,
} from "utils/ethereum/contracts";
import { getDataToUpdatePrice } from "utils/ethereum/getDataToUpdatePrice";
import { formatUnits } from "utils/number";
import {
  decodeResultMulticallZkSync,
  formatMulticallResults,
} from "utils/contract/decodeResultMulticall";
import handleGetListTokenProvider from "utils/ethereum/handleGetListTokenProvider";
import { REACT_APP_ACCOUNT_HAVING_ETH } from "constants/NetworkChainId";
import {
  getLvrFromResult,
  handleGetDepositTokens,
  handleGetPriceInUsd,
} from "./helper/getDataContract";
import { useGetTokens } from "./useTokenSupported";

const localChainId = localStorage.getItem("chainId");

const GET_PRICE_ZKSYNC_METHOD = "getUpdatedPrice";

const getRequestMulticallZksync = async (
  availableBorrowTokens,
  listToken,
  account,
  signer,
  PriceContractInfo,
  PITToken,
  UniswapV2FactoryInfo,
  chainId,
  priceProviderList
) => {
  const pythAddress = getPythProvider(chainId).address;

  /* A list of requests to token contract. */
  const listRequestToTokenContracts = [];

  /* A list of requests to Price contract. */
  const listRequestToPriceContract = [];

  /* A list of requests to PIT contract. */
  const listRequestToPITContract = [];

  /* A list of requests to Uniswapv2Factory contract */
  const listRequestToUniswapV2FactoryContract = [];

  const PITContract = new Contract(PITToken.address, PITToken.abi, signer);
  const PriceContract = new Contract(PriceContractInfo.address, PriceContractInfo.abi, signer);
  const UniswapV2FactoryContract = new Contract(
    UniswapV2FactoryInfo.address,
    UniswapV2FactoryInfo.abi,
    signer
  );

  for (let idx = 0; idx < availableBorrowTokens.length; idx += 1) {
    const token = availableBorrowTokens[idx];
    const TokenContract = new Contract(token.address, ERC20TokenABI, signer);

    listRequestToTokenContracts.push(
      {
        target: token.address,
        callData: TokenContract.interface.encodeFunctionData("allowance", [
          account,
          PITToken.address,
        ]),
        reference: "isAllowanceForPIT",
        methodName: "allowance",
        methodParameters: [account, PITToken.address],
        contract: TokenContract,
        contractName: token.address,
        value: BigNumber.from(0),
      },
      {
        target: token.address,
        callData: TokenContract.interface.encodeFunctionData("balanceOf", [account]),
        reference: "balanceOfWallet",
        methodName: "balanceOf",
        methodParameters: [account],
        contract: TokenContract,
        contractName: token.address,
        value: BigNumber.from(0),
      },
      {
        target: token.address,
        callData: TokenContract.interface.encodeFunctionData("decimals", []),
        reference: "decimal",
        methodName: "decimals",
        methodParameters: [],
        contract: TokenContract,
        contractName: token.address,
        value: BigNumber.from(0),
      }
    );

    const tokenPriceProvider = priceProviderList[token.address];

    const isUsingPyth = pythAddress.toString() === tokenPriceProvider.toString();

    let payableAmount = BigNumber.from(0);
    let updateData = [];

    if (isUsingPyth) {
      const dataUpdatePrice = await getDataToUpdatePrice([token.address], PriceContract);
      payableAmount = dataUpdatePrice.payableAmount;
      updateData = dataUpdatePrice.updateData;
    }

    listRequestToPriceContract.push({
      target: PriceContract.address,
      callData: PriceContract.interface.encodeFunctionData(GET_PRICE_ZKSYNC_METHOD, [
        token.address,
        updateData,
      ]),
      reference: token.address,
      methodName: GET_PRICE_ZKSYNC_METHOD,
      methodParameters: [token.address, updateData],
      value: payableAmount,
      contract: PriceContract,
      contractName: "PriceContract",
    });
  }

  for (let idx = 0; idx < listToken.length; idx += 1) {
    const token = listToken[idx];
    const TokenContract = new Contract(token.address, ERC20TokenABI, signer);

    listRequestToPITContract.push(
      {
        target: PITToken.address,
        callData: PITContract.interface.encodeFunctionData("projectTokenInfo", [token.address]),
        reference: `${token.address}`,
        methodName: "projectTokenInfo",
        contract: PITContract,
        contractName: "PITContract",
        methodParameters: [token.address],
        value: BigNumber.from(0),
      },
      {
        target: PITToken.address,
        callData: PITContract.interface.encodeFunctionData("pitCollateral", [
          account,
          token.address,
        ]),
        reference: `${token.address}`,
        contract: PITContract,
        contractName: "PITContract",
        methodName: "pitCollateral",
        methodParameters: [account, token.address],
        value: BigNumber.from(0),
      }
    );

    const tokenPriceProvider = priceProviderList[token.address];

    const isUsingPyth = pythAddress.toString() === tokenPriceProvider.toString();

    let payableAmount = BigNumber.from(0);
    let updateData = [];

    if (isUsingPyth) {
      const dataUpdatePrice = await getDataToUpdatePrice([token.address], PriceContract);
      payableAmount = dataUpdatePrice.payableAmount;
      updateData = dataUpdatePrice.updateData;
    }

    listRequestToPriceContract.push({
      target: PriceContract.address,
      callData: PriceContract.interface.encodeFunctionData(GET_PRICE_ZKSYNC_METHOD, [
        token.address,
        updateData,
      ]),
      reference: token.address,
      methodName: GET_PRICE_ZKSYNC_METHOD,
      methodParameters: [token.address, updateData],
      value: payableAmount,
      contract: PriceContract,
      contractName: "PriceContract",
    });

    listRequestToTokenContracts.push(
      {
        target: token.address,
        callData: TokenContract.interface.encodeFunctionData("allowance", [
          account,
          PITToken.address,
        ]),
        reference: "isAllowanceForPIT",
        methodName: "allowance",
        methodParameters: [account, PITToken.address],
        contract: TokenContract,
        contractName: token.address,
        value: BigNumber.from(0),
      },
      {
        target: token.address,
        callData: TokenContract.interface.encodeFunctionData("balanceOf", [account]),
        reference: "balanceOfWallet",
        methodName: "balanceOf",
        methodParameters: [account],
        contract: TokenContract,
        contractName: token.address,
        value: BigNumber.from(0),
      },
      {
        target: token.address,
        callData: TokenContract.interface.encodeFunctionData("decimals", []),
        reference: "decimal",
        methodName: "decimals",
        methodParameters: [],
        contract: TokenContract,
        contractName: token.address,
        value: BigNumber.from(0),
      }
    );
  }

  if (!MainNetworkSupported.includes(+chainId)) {
    listToken.forEach((collateralToken) => {
      availableBorrowTokens.forEach((lendingToken) => {
        listRequestToUniswapV2FactoryContract.push({
          target: UniswapV2FactoryInfo.address,
          callData: UniswapV2FactoryContract.interface.encodeFunctionData("getPair", [
            collateralToken.address,
            lendingToken.address,
          ]),
          contract: UniswapV2FactoryContract,
          contractName: "UniswapV2FactoryContract",
          reference: `${collateralToken.address}`,
          methodName: "getPair",
          methodParameters: [collateralToken.address, lendingToken.address],
          value: BigNumber.from(0),
        });
      });
    });
  }

  return [
    ...listRequestToTokenContracts,
    ...listRequestToPriceContract,
    ...listRequestToPITContract,
    ...listRequestToUniswapV2FactoryContract,
  ];
};

const useAvailableMultiCall = () => {
  const { loading, projectTokenList: collaterals, availableBorrowTokens } = useGetTokens();
  const { chainId, account, signer, provider, fetchNativeBalance } = useWallet();

  const multiCallContract = useMultiCallContractInstance();

  const isMainNet = MainNetworkSupported.includes(+chainId);

  const { checkLeveragePosition } = useCheckLeveragePositions(
    [...collaterals].map((o) => o?.address)
  );

  const { getLeverageTypes } = useGetLeverageTypes([...collaterals].map((o) => o?.address));

  const PriceContractInfo = getPriceProviderAggregatorContract(chainId ?? localChainId);

  const collateralKeys = collaterals
    .map((c) => c.address)
    .sort()
    .join(",");
  return useQuery(
    ["available-multicall", account, collateralKeys, chainId],
    async () => {
      const { data: leveragePositionMap } = await checkLeveragePosition();
      const { data: leverageType } = await getLeverageTypes();

      const listToken = [...collaterals];

      const PITToken = getPrimaryIndexTokenZksync(chainId ?? localChainId);
      const UniswapV2FactoryContract = getUniswapV2Factory(chainId ?? localChainId);

      const priceProviderList = await handleGetListTokenProvider(
        // reduce duplicate
        Array.from(
          new Set([...availableBorrowTokens, ...listToken].map((token) => token.address))
        ).map((address) => ({ address })),

        PriceContractInfo,
        provider,
        multiCallContract,
        chainId
      );

      const [...listRequestZksync] = await getRequestMulticallZksync(
        availableBorrowTokens,
        listToken,
        account,
        signer,
        PriceContractInfo,
        PITToken,
        UniswapV2FactoryContract,
        chainId,
        priceProviderList
      );

      const valueOfRequest = listRequestZksync.map((r) =>
        "value" in r ? r.value : BigNumber.from(0)
      );
      const totalValue = valueOfRequest.reduce((pre, cur) => pre.add(cur), BigNumber.from(0));

      const resultMulticall = await multiCallContract.callStatic.aggregate3Value(
        listRequestZksync,
        {
          value: totalValue,
          from: REACT_APP_ACCOUNT_HAVING_ETH[chainId],
        }
      ).catch(error => {
        console.log("Request multicall fail", listRequestZksync);
        throw error
      });
      const returnData = formatMulticallResults(resultMulticall);

      const results = {
        UniswapV2FactoryContract: [],
        ...decodeResultMulticallZkSync(listRequestZksync, returnData),
      };

      const { name: nativeName, symbol: nativeSymbol, logo: nativeLogo } = wrapNativeToken(chainId);
      const nativeBalanceData = await fetchNativeBalance();
      const nativeBalance = nativeBalanceData?.data?.formatted;
      let userTokenInfo = [...availableBorrowTokens, ...listToken].map((token) => {
        const tokenContract = get(results, [token.address], []);

        const methodData = groupBy(tokenContract, "methodName");

        const balanceOf = get(methodData, ["balanceOf", 0, "returnValues", 0], "0");
        const decimal = get(methodData, ["decimals", 0, "returnValues", 0], "0");

        const isNative = isWrapNative(token.address, chainId);
        return {
          price: 0,
          ...token,
          allowance: !!get(methodData, ["allowance", 0, "returnValues"], false),
          balanceOf: isNative ? nativeBalance : formatUnits(balanceOf, decimal),
          name: isNative ? nativeName : token.name,
          symbol: isNative ? nativeSymbol : token.symbol,
          logo: isNative ? nativeLogo : token.logo,
          decimalNumber: formatUnits(decimal, 0),
        };
      });

      const availableDepositTokens = handleGetDepositTokens({
        results: { ...results },
        listToken,
        isMainNet,
      }).map((o) => {
        const isNative = isWrapNative(o.address, chainId);
        return {
          ...o,
          balance: isNative ? nativeBalance : o.balance,
          price: isNative ? nativeBalance * (o.price / o.balance) : o.price,
          name: isNative ? nativeName : o.name,
          symbol: isNative ? nativeSymbol : o.symbol,
          logo: isNative ? nativeLogo : o.logo,
          isLeverage: leveragePositionMap?.get(o.address),
          leverageType: leverageType?.get(o.address),
        };
      });

      const lvrByProjectTokens = listToken.reduce(
        (res, o) => ({
          ...res,
          [o.address]: getLvrFromResult(results, o.address),
        }),
        {}
      );

      const priceOfTokens = [...listToken, ...availableBorrowTokens].reduce((res, o) => {
        const { priceTokenBN, priceDecimal } = handleGetPriceInUsd(results, o.address);

        return {
          ...res,
          [o.address]: formatUnits(priceTokenBN, priceDecimal),
        };
      }, {});

      // inject price to userTokenInfo
      userTokenInfo = userTokenInfo.map((x) => ({
        ...x,
        price: priceOfTokens[x.address] || 0,
      }));

      const decimalOfContractToken = {};
      map(omit(results, ["PriceContract", isMainNet && "UniswapV2FactoryContract"]), (obj) => {
        const key = get(obj, [0, "contract", "address"]).toLowerCase();

        const value = get(groupBy(obj, "methodName"), ["decimals", 0, "returnValues", 0], 0);

        decimalOfContractToken[key] = +value;
      });

      return {
        availableDepositTokens,
        userTokenInfo,
        decimalOfContractToken,
        lvrByProjectTokens,
        priceOfTokens,
      };
    },
    {
      enabled:
        !loading &&
        !!account &&
        !!collaterals?.length &&
        !!availableBorrowTokens?.length &&
        !!chainId,
    }
  );
};

export default useAvailableMultiCall;
