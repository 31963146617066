import { constants } from "ethers";
import useWallet from "hooks/useWallet";
import { useQuery } from "react-query";

import { useUniswapV2FactoryContract } from "hooks/contract/core/useUniswapV2FactoryContract";
import { useMuteFactoryContract } from "hooks/contract/core/useMuteContract";
import { getPriceParaswap } from "utils/ethereum/getPriceParaswap";
import { useBorrowContext } from "context/contracts/BorrowContext";
import { parseUnits } from "utils/number";
import { MainNetworkSupported, ZERO_ADDRESS, isZksyncNetwork } from "utils/addressUtils";
import { getDataFromOpenOcean } from "utils/ethereum/getDataFromOpenOcean";

export const useGetPair = (prjToken, lendingToken) => {
  const { account, chainId, signer } = useWallet();
  const { uniswapV2Factory } = useUniswapV2FactoryContract(signer);
  const { decimalOfContractToken } = useBorrowContext();

  const pair = useQuery(["get-pair", account, chainId, prjToken, lendingToken], async () => {
    if (MainNetworkSupported.includes(+chainId)) {
      try {
        const isZkSync = isZksyncNetwork(chainId);

        if (isZkSync) {
          const data = await getDataFromOpenOcean(
            prjToken,
            lendingToken,
            chainId,
            "1",
            signer,
            account
          );

          if (data) return prjToken;
        }

        const priceData = await getPriceParaswap(
          { chainId },
          prjToken,
          lendingToken,
          parseUnits("1", decimalOfContractToken[prjToken]),
          decimalOfContractToken[prjToken],
          decimalOfContractToken[lendingToken]
        );

        if (priceData) return prjToken;

        return ZERO_ADDRESS;
      } catch (error) {
        return ZERO_ADDRESS;
      }
    } else {
      if (!uniswapV2Factory) {
        return constants.AddressZero;
      }
      const res = await uniswapV2Factory.getPair(prjToken, lendingToken);
      return res;
    }
  });

  return pair.data;
};

export const useGetPairMute = (prjToken, lendingToken) => {
  const { account, chainId } = useWallet();
  const { useMuteFactory } = useMuteFactoryContract();

  const pair = useQuery(["get-pair", account, chainId, prjToken, lendingToken], async () => {
    if (!useMuteFactory) {
      return constants.AddressZero;
    }
    const res = await useMuteFactory.getPair(prjToken, lendingToken, [true]);
    return res;
  });

  return pair.data;
};
