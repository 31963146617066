import { DECIMAL_ETHER } from "app/constants";
import { BigNumber, Contract, constants } from "ethers";
import _get from "lodash/get";
import _reduce from "lodash/reduce";
import { useQuery } from "react-query";
import {
  decodeResultMulticallZkSync,
  formatMulticallResults,
} from "utils/contract/decodeResultMulticall";
import { bUSDCContractABI } from "utils/ethereum/abi";
import { REACT_APP_ACCOUNT_HAVING_ETH } from "constants/NetworkChainId";
import { useGetBlocksPer } from "./common/useGetBlocksPer";
import { useMultiCallContractInstance } from "./contract/multicall/useMultiCallContract";
import useWallet from "./useWallet";

const methodOnFTokenContract = {
  borrowRatePerBlock: "borrowRatePerBlock",
  supplyRatePerBlock: "supplyRatePerBlock",
};

export const getApy = (rate, blockPerYear) => {
  // eslint-disable-next-line no-restricted-properties
  const rateIntMath = Math.pow(+rate / 10 ** DECIMAL_ETHER + 1, blockPerYear) - 1;

  return rateIntMath.toString();
};

const useGetApyOnToken = (fTokens = []) => {
  const { provider, chainId } = useWallet();
  const { blocksPerYear } = useGetBlocksPer();

  const multiCallSMC = useMultiCallContractInstance();

  return useQuery(
    ["apy-pit-query", chainId],
    async () => {
      const listRequest = [];

      fTokens.forEach((o) => {
        const FTokenContract = new Contract(o.ftoken, bUSDCContractABI, provider);

        listRequest.push(
          {
            target: o.ftoken,
            callData: FTokenContract.interface.encodeFunctionData(
              methodOnFTokenContract.borrowRatePerBlock,
              []
            ),
            methodName: methodOnFTokenContract.borrowRatePerBlock,
            methodParameters: [],
            reference: o.token,
            contract: FTokenContract,
            contractName: o.ftoken,
            value: BigNumber.from(0),
          },
          {
            target: o.ftoken,
            callData: FTokenContract.interface.encodeFunctionData(
              methodOnFTokenContract.supplyRatePerBlock,
              []
            ),
            methodName: methodOnFTokenContract.supplyRatePerBlock,
            methodParameters: [],
            reference: o.token,
            contract: FTokenContract,
            contractName: o.ftoken,
            value: BigNumber.from(0),
          }
        );
      });
      const valueOfRequest = listRequest.map((r) => ("value" in r ? r.value : BigNumber.from(0)));
      const totalValue = valueOfRequest.reduce((pre, cur) => pre.add(cur), BigNumber.from(0));

      const resultMulticall = await multiCallSMC.callStatic.aggregate3Value(listRequest, {
        value: totalValue,
        from: REACT_APP_ACCOUNT_HAVING_ETH[chainId],
      }).catch(error => {
        console.log("Request multicall fail", listRequest);
        throw error
      });
      const returnData = formatMulticallResults(resultMulticall);

      const results = decodeResultMulticallZkSync(listRequest, returnData);

      const fTokenApyList = _reduce(
        results,
        (result, value, key) => {
          const borrowRate = _get(value, [1, "returnValues", 0], BigNumber.from(0));

          const lenderRate = _get(value, [0, "returnValues", 0], BigNumber.from(0));

          const token = _get(value, [0, "reference"], constants.AddressZero);

          const data = {
            lenderApy: getApy(+borrowRate, +blocksPerYear),
            borrowApy: getApy(+lenderRate, +blocksPerYear),
            borrowRate: +borrowRate,
            lenderRate: +lenderRate,
            ftoken: key,
          };
          return { ...result, [token]: data };
        },
        {}
      );

      return fTokenApyList;
    },
    {
      enabled: Boolean(fTokens.length),
    }
  );
};

export default useGetApyOnToken;
