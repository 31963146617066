import { constants, Contract, ethers } from "ethers";

import { isEqualLowerString, isZksyncNetwork, MainNetworkSupported } from "utils/addressUtils";
import { formatUnits } from "utils/number";
import { getUniswapV2Factory } from "./contracts";
import ParaSwapAdapterARTIFACT from "./json/NewUniswapV2Router.json";
import UniSwapV2PairARTIFACT from "./json/UniswapV2Pair.json";
import { createCallDataParaswap } from "./createCallDataParaswap";
import { getDataFromOpenOcean } from "./getDataFromOpenOcean";

const createBuyCallDataOnTestNet = async (
  tokenCollateral,
  amountInMax,
  amountOut,
  weth,
  lendingToken,
  { chainId, signer }
) => {
  const uniswapInfo = getUniswapV2Factory(chainId);
  const uniswapContract = new Contract(uniswapInfo?.address, uniswapInfo?.abi, signer);

  const usdcToken = localStorage.getItem("usdcToken");
  let pools = [];
  if (isEqualLowerString(lendingToken, usdcToken)) {
    const pairs2 = await uniswapContract.getPair(tokenCollateral, usdcToken);
    pools = [pairs2];
  } else if (!usdcToken) {
    const pairs1 = await uniswapContract.getPair(lendingToken, tokenCollateral);
    pools = [pairs1];
  } else {
    const pairs1 = await uniswapContract.getPair(tokenCollateral, usdcToken);
    const pairs2 = await uniswapContract.getPair(lendingToken, usdcToken);
    pools = [pairs1, pairs2];
  }

  let tokenInNext;

  const poolsList = await Promise.all(
    pools.map(async (o, i) => {
      const pairToken = new ethers.Contract(pools[i], UniSwapV2PairARTIFACT, signer);
      const token0 = await pairToken.token0();
      const token1 = await pairToken.token1();

      let prefix;

      if (i === 0) {
        if (isEqualLowerString(tokenCollateral, token0)) {
          prefix = "4de4";
          if (typeof token1?.toLowerCase() === "undefined") tokenInNext = token1?.toLowerCase();
        } else {
          prefix = "4de5";
          tokenInNext = token0?.toLowerCase();
        }
      } else if (isEqualLowerString(tokenInNext, token0)) {
        prefix = "4de4";
        tokenInNext = token1?.toLowerCase();
      } else {
        prefix = "4de5";
        tokenInNext = token0?.toLowerCase();
      }
      const convertedPool = pools[i].slice(0, 2) + prefix + pools[i].slice(2);
      return convertedPool.toLowerCase();
    })
  );

  const iface = new ethers.utils.Interface(ParaSwapAdapterARTIFACT);
  const result = await iface.encodeFunctionData("buyOnUniswapV2Fork", [
    tokenCollateral,
    amountInMax,
    amountOut,
    weth,
    poolsList,
  ]);
  return result;
};

export const createBuyCallData = async (
  tokenCollateral,
  amountInMax,
  amountOut,
  lendingToken = "",
  { chainId, signer, account }
) => {
  if (MainNetworkSupported.includes(+chainId)) {
    const isZkSync = isZksyncNetwork(chainId);

    if (isZkSync) {
      const { data, code } = await getDataFromOpenOcean(
        tokenCollateral.address,
        lendingToken.address,
        chainId,
        formatUnits(amountInMax, tokenCollateral.decimal || 18),
        signer,
        account
      );

      if (code !== 200) {
        throw new Error(data, { cause: "Cannot fetch Open Ocean data" });
      }

      return data.data;
    }

    return createCallDataParaswap(
      tokenCollateral.address,
      tokenCollateral.decimal,
      lendingToken.address,
      lendingToken.decimal,
      amountOut.toString(),
      "BUY",
      {
        chainId,
        account,
      }
    );
  }

  return createBuyCallDataOnTestNet(
    tokenCollateral.address,
    amountInMax,
    amountOut,
    constants.AddressZero,
    lendingToken.address,
    {
      chainId,
      signer,
    }
  );
};
