/* eslint-disable no-unused-vars */
import { gql, useQuery as useClientQuery } from "@apollo/client";
import { BigNumber, Contract, ethers } from "ethers";
import { get, groupBy } from "lodash";
import { useMemo } from "react";
import { useQuery } from "react-query";
import {
  // eslint-disable-next-line import/named
  useDefaultMultiCallContractInstance,
} from "hooks/contract/multicall/useMultiCallContract";
import {
  decodeResultMulticallZkSync,
  formatMulticallResults,
} from "utils/contract/decodeResultMulticall";
import {
  getPrimaryIndexToken,
  getPriceProviderAggregatorContract,
  getPythProvider,
} from "utils/ethereum/contracts";
import { getLogoBySymbolAndName, getSpecialTokenSymbol } from "utils/utils";
import { useProjectTokensQuery } from "hooks/contexts/ProjectTokenContext/ProjectTokenContext";
import { useChainId, useAccount } from "wagmi";
import { defaultProvider, defaultNetwork, wrapNativeToken, isWrapNative } from "utils/addressUtils";
import { FTokenABI } from "utils/ethereum/abi";
import { formatUnits } from "utils/number";
import { getDataToUpdatePrice } from "utils/ethereum/getDataToUpdatePrice";
import { getLvrFromResult } from "./helper/getDataContract";

const mappingNativeToken = (token, chainId) => {
  const isNative = isWrapNative(token.address, ethers.utils.hexValue(chainId));
  const { name, symbol, logo } = wrapNativeToken(ethers.utils.hexValue(chainId));
  if (!isNative) {
    return {
      ...token,
      symbol: getSpecialTokenSymbol(token.address, chainId) || token.symbol,
      logo:
        getLogoBySymbolAndName(token.symbol, token.name, token?.address) ||
        "https://s2.coinmarketcap.com/static/img/coins/64x64/3267.png",
    };
  }
  return {
    ...token,
    name,
    symbol,
    logo,
  };
};

export const useGetTokens = () => {
  const chainId = useChainId();

  const { data, refetch } = useProjectTokensQuery();

  return {
    ...data,
    projectTokenList: get(data, ["projectTokens"], []).map((o) => ({
      ...mappingNativeToken(o, chainId),
      type: "projectToken",
      underlyingTokens: o.underlyingTokens.map((x) => mappingNativeToken(x, chainId)),
    })),
    updateDataGraph: refetch,
    borrowLogs: groupBy(get(data, ["borrowLogs"]), "prjTokenAddress"),
    availableBorrowTokens: get(data, ["lendingTokens"], []).map((o) => ({
      ...mappingNativeToken(o, chainId),
      type: "lendingToken",
    })),
  };
};

export const GET_PRJ_TOKEN_WITHOUT_ACCOUNT = gql`
  query ExampleQuery {
    projectTokens {
      name
      symbol
      address
      underlyingTokens {
        id
        name
        symbol
        address
        linksNumber
      }
    }
    lendingTokens {
      name
      symbol
      address
    }
  }
`;

function getRequestMulticall(PITToken, PriceContractInfo, projectTokens, lendingTokens, provider) {
  if (!PITToken.address) return null;

  const listRequest = [];

  const PITContract = new Contract(PITToken.address, PITToken.abi, provider);
  const PriceContract = new Contract(PriceContractInfo.address, PriceContractInfo.abi, provider);
  projectTokens.forEach((token) => {
    listRequest.push({
      target: PITToken.address,
      callData: PITContract.interface.encodeFunctionData("projectTokenInfo", [token.address]),
      reference: `${token.address}`,
      methodName: "projectTokenInfo",
      contract: PITContract,
      contractName: "PITContract",
      methodParameters: [token.address],
      value: ethers.BigNumber.from(0),
    });
  });

  lendingTokens.forEach((token) => {
    listRequest.push(
      {
        target: PITToken.address,
        callData: PITContract.interface.encodeFunctionData("lendingTokenInfo", [token.address]),
        reference: `${token.address}`,
        methodName: "lendingTokenInfo",
        contract: PITContract,
        contractName: "PITContract",
        methodParameters: [token.address],
        value: ethers.BigNumber.from(0),
      },
      {
        target: PriceContract.address,
        callData: PriceContract.interface.encodeFunctionData("tokenPriceProvider", [token.address]),
        reference: `${token.address}`,
        methodName: "tokenPriceProvider",
        contract: PriceContract,
        contractName: "PriceContract",
        methodParameters: [token.address],
        value: ethers.BigNumber.from(0),
      }
    );
  });

  return listRequest;
}

function getCashRequestMulticall(lendingTokens, provider) {
  const listRequestToTokenContracts = [];
  lendingTokens.forEach((token) => {
    const FTokenContract = new Contract(token.fToken, FTokenABI, provider);
    listRequestToTokenContracts.push(
      {
        target: token.fToken,
        callData: FTokenContract.interface.encodeFunctionData("getCash", []),
        methodParameters: [],
        reference: "CashForLendingToken",
        methodName: "getCash",
        contractName: token.fToken,
        contract: FTokenContract,
        value: ethers.BigNumber.from(0),
      },
      {
        target: token.fToken,
        callData: FTokenContract.interface.encodeFunctionData("decimals", []),
        methodParameters: [],
        reference: token.address,
        methodName: "decimals",
        contractName: token.fToken,
        contract: FTokenContract,
        value: ethers.BigNumber.from(0),
      }
    );
  });
  return listRequestToTokenContracts;
}

async function getCash(lendingTokens, multiCallContract) {
  const cashRequest = getCashRequestMulticall(lendingTokens, defaultProvider);
  const cashResultMulticall = await multiCallContract.callStatic.aggregate3Value(cashRequest, {
    value: 0,
  }).catch(error => {
    console.log("Request multicall fail", cashRequest);
    throw error
  });
  const cashReturnData = formatMulticallResults(cashResultMulticall);
  const cashResults = decodeResultMulticallZkSync(cashRequest, cashReturnData);
  let cashFToken = {};
  Object.keys(cashResults).forEach((fTokenAddress) => {
    const current = cashResults[fTokenAddress];
    const cashValue = get(current, ["0", "returnValues", 0], "0");
    const decimal = get(current, ["1", "returnValues", 0], "0");
    const lendingToken = get(current, ["1", "reference"], "");

    cashFToken = {
      ...cashFToken,
      [lendingToken]: {
        cash: formatUnits(cashValue, decimal),
      },
    };
  });
  return cashFToken;
}

function handleDataFromMulticall(result, projectTokenList, lendingTokenList) {
  const PITContract = get(result, ["PITContract"], []);
  const PriceContract = get(result, ["PriceContract"], []);
  const projectTokens = PITContract.filter(
    (params) => params.methodName === "projectTokenInfo"
  ).map((token) => {
    const prjTokenAddress = get(token, ["methodParameters", 0], "");
    const tokenInfo = projectTokenList.find((o) => o.address === prjTokenAddress);
    const lvr = getLvrFromResult(result, prjTokenAddress);

    return {
      ...tokenInfo,
      lvr,
      logo: getLogoBySymbolAndName(tokenInfo.symbol, tokenInfo.name, tokenInfo?.address),
    };
  });

  const lendingTokens = PITContract.filter(
    (params) => params.methodName === "lendingTokenInfo"
  ).map((token) => {
    const lendingTokenAddress = get(token, ["methodParameters", 0], "");
    const fToken = get(token, ["returnValues", 2], "");
    const tokenInfo = lendingTokenList.find((o) => o.address === lendingTokenAddress);
    const lvr = getLvrFromResult(result, lendingTokenAddress);

    return {
      ...tokenInfo,
      fToken,
      lvr,
      logo: getLogoBySymbolAndName(tokenInfo.symbol, tokenInfo.name, tokenInfo?.address),
    };
  });

  const priceProviders = {};
  PriceContract.forEach((priceData) => {
    const address = get(priceData, ["methodParameters", 0], "");
    const priceProvider = get(priceData, ["returnValues", 0], "");
    priceProviders[address] = priceProvider;
  });

  return { projectTokens, lendingTokens, priceProviders };
}

export async function getPrice(
  formattedLendingTokens = [],
  priceProviders,
  PriceContractInfo,
  multiCallContract,
  provider
) {
  const PriceContract = new Contract(
    PriceContractInfo.address,
    PriceContractInfo.abi,
    provider || defaultProvider
  );
  const pythAddress = getPythProvider(ethers.utils.hexValue(defaultNetwork.id)).address;
  const listRequestPrice = [];

  for (let i = 0; i < formattedLendingTokens.length; i += 1) {
    const token = formattedLendingTokens[i];
    const tokenPriceProvider = priceProviders[token.address];

    const isUsingPyth = pythAddress?.toString() === tokenPriceProvider.toString();

    let payableAmount = BigNumber.from(1);
    let updateData = [];

    if (isUsingPyth) {
      // eslint-disable-next-line no-await-in-loop
      const dataUpdatePrice = await getDataToUpdatePrice([token.address], PriceContract);
      payableAmount = dataUpdatePrice.payableAmount;
      updateData = dataUpdatePrice.updateData;
    }

    listRequestPrice.push({
      target: PriceContract.address,
      callData: PriceContract.interface.encodeFunctionData("getUpdatedPrice", [
        token.address,
        updateData,
      ]),
      reference: token.address,
      methodName: "getUpdatedPrice",
      methodParameters: [token.address, updateData],
      value: payableAmount,
      contract: PriceContract,
      contractName: "PriceProviderContract",
    });
  }
  const valueOfRequest = listRequestPrice.map((r) => ("value" in r ? r.value : BigNumber.from(0)));
  const totalValue = valueOfRequest?.reduce((pre, cur) => pre.add(cur), BigNumber.from(0));
  const priceResultMulticall = await multiCallContract.callStatic.aggregate3Value(
    listRequestPrice,
    {
      value: totalValue,
    }
  ).catch(error => {
    console.log("Request multicall fail", listRequestPrice);
    throw error
  });
  const priceReturnData = formatMulticallResults(priceResultMulticall);
  const priceResults = decodeResultMulticallZkSync(listRequestPrice, priceReturnData);
  const prices = {};
  priceResults?.PriceProviderContract?.forEach((o) => {
    const priceBN = get(o, ["returnValues", 0], 0);
    const priceDecimal = get(o, ["returnValues", 1], 0);
    const lendingToken = get(o, ["reference"], "");
    prices[lendingToken] = formatUnits(priceBN, priceDecimal);
  });
  return prices;
}

export const useGetTokensWithoutAccount = () => {
  const { data } = useClientQuery(GET_PRJ_TOKEN_WITHOUT_ACCOUNT);
  const { address: account } = useAccount();

  const projectTokens = useMemo(() => get(data, "projectTokens", []), [data]);
  const lendingTokens = useMemo(() => get(data, "lendingTokens", []), [data]);

  const multiCallContract = useDefaultMultiCallContractInstance();

  const keys = Array.from(new Set([...projectTokens, ...lendingTokens].map((s) => s.address)))
    .sort()
    .join(",");
  // const eth = new Eth(metamaskProvider);
  const listTokenWithoutAccount = useQuery(
    ["get-token-without-account", keys],
    async () => {
      const chainId = ethers.utils.hexValue(defaultNetwork.id);

      const PITToken = getPrimaryIndexToken(chainId);
      const PriceContract = getPriceProviderAggregatorContract(chainId);

      const listRequest = getRequestMulticall(
        PITToken,
        PriceContract,
        projectTokens,
        lendingTokens,
        defaultProvider
      );

      const valueOfRequest = listRequest?.map((r) => ("value" in r ? r.value : BigNumber.from(0)));
      const totalValue = valueOfRequest?.reduce((pre, cur) => pre.add(cur), BigNumber.from(0));

      const resultMulticall = await multiCallContract.callStatic.aggregate3Value(listRequest, {
        value: totalValue,
      }).catch(error => {
        console.log("Request multicall fail", listRequest);
        throw error
      });

      const returnData = formatMulticallResults(resultMulticall);

      const results = decodeResultMulticallZkSync(listRequest, returnData);

      const {
        projectTokens: formattedProjectTokens,
        lendingTokens: formattedLendingTokens,
        priceProviders,
      } = handleDataFromMulticall(results, projectTokens, lendingTokens);

      const cashFToken = await getCash(formattedLendingTokens, multiCallContract);
      return {
        projectTokens: formattedProjectTokens.map((token) => ({
          ...mappingNativeToken(token, defaultNetwork.id),
          allowance: false,
          balance: "0.0",
          healthFactor: 0,
          price: 0,
          isLeverage: false,
        })),
        lendingTokens: formattedLendingTokens.map((token) => ({
          ...mappingNativeToken(token, defaultNetwork.id),
          cash: cashFToken[token.address]?.cash,
          balanceOf: "0.0",
          price: 0,
          balanceInUsd: "0",
          balanceOfUnderlyingView: "0",
          apy: "0",
        })),
        priceProviders,
      };
    },
    {
      enabled: !account && !!lendingTokens?.length && !!projectTokens?.length,
    }
  );

  return {
    data: listTokenWithoutAccount.data,
    error: listTokenWithoutAccount.isError,
    isLoading: listTokenWithoutAccount.isLoading,
  };
};

export const usePriceTokens = (tokens, priceProviders) => {
  const multiCallContract = useDefaultMultiCallContractInstance();
  const { address: account } = useAccount();
  const keys = tokens
    ? Array.from(tokens.map((s) => s.address))
        .sort()
        .join(",")
    : "";
  // const eth = new Eth(metamaskProvider);
  const getPriceTokens = useQuery(
    ["get-price-tokens", keys],
    async () => {
      const chainId = ethers.utils.hexValue(defaultNetwork.id);

      const PriceContract = getPriceProviderAggregatorContract(chainId);

      const prices = await getPrice(tokens, priceProviders, PriceContract, multiCallContract);

      return prices;
    },
    { enabled: !account && tokens?.length > 0 && !!priceProviders }
  );

  return {
    data: getPriceTokens.data,
    error: getPriceTokens.isError,
    isLoading: getPriceTokens.isLoading,
  };
};
