import MultiCallV2 from "./json/multicallABI.json";
import MultiCallZkSyncV2 from "./json/multicallZksyncABI.json";
import PITContractABI from "./json/PITContractABI.json";
import PITContractZksyncABI from "./json/PITContractZksyncABI.json";
import PriceABI from "./json/PriceContractABI.json";
import PriceZksyncABI from "./json/PriceContractZksyncABI.json";
import FTokenAbi from "./json/FTokenABI.json";
import AtomicRepayment from "./json/PrimaryIndexTokenAtomicRepayment.json";
import AtomicRepaymentZkSync from "./json/PrimaryIndexTokenAtomicRepaymentZkSync.json";
import UniswapV2Factory from "./json/UniswapV2Factory.json";
import UniswapV2Router from "./json/UniswapV2Router.json";
import InstantLeverage from "./json/instantLeverage.json";
import InstantLeverageZkSync from "./json/instantLeverageZkSync.json";
import JumpRateModelAbi from "./json/JumpRateModelABI.json";
import MuteRouterAbi from "./json/MuteRouter.json";
import MuteFactoryAbi from "./json/MuteFactory.json";
import MutePairAbi from "./json/MutePair.json";
import PrimaryLendingWrappedTokenGatewayAbi from "./json/PrimaryLendingWrappedTokenGateway.json";

export const MultiCallABI = MultiCallV2;
export const MultiCallZkSyncABI = MultiCallZkSyncV2;

export const PrimaryIndexTokenABI = PITContractABI;
export const PrimaryIndexTokenZksyncABI = PITContractZksyncABI;

export const ERC20TokenABI = [
  "function balanceOf(address _owner) public view returns (uint256 balance)",
  "function decimals() public view returns (uint8)",
  "function symbol() public view returns (string)",
  "function name() public view returns (string)",
  "function allowance(address _owner, address _spender) public view returns (uint256 remaining)",
  "function approve(address _spender, uint256 _value) public returns (bool success)",
];

export const bUSDCContractABI = FTokenAbi;

export const PriceProviderAggregatorABI = PriceABI;
export const PriceProviderAggregatorZksyncABI = PriceZksyncABI;

export const FTokenABI = FTokenAbi;

export const AtomicRepaymentAbi = AtomicRepayment;
export const AtomicRepaymentZksyncAbi = AtomicRepaymentZkSync;

export const UniswapV2FactoryAbi = UniswapV2Factory;

export const InstantLeverageAbi = InstantLeverage;
export const InstantLeverageZkSyncAbi = InstantLeverageZkSync;

export const UniswapV2RouterAbi = UniswapV2Router;
export const JumpRateModelABI = JumpRateModelAbi;

export const MuteRouterABI = MuteRouterAbi;
export const MuteFactoryABI = MuteFactoryAbi;
export const MutePairABI = MutePairAbi;

export const PrimaryLendingWrappedTokenGatewayABI = PrimaryLendingWrappedTokenGatewayAbi;
