import { Box, Button, CircularProgress, Grid, Tooltip, Typography } from "@material-ui/core";
import { constants } from "ethers";
import _get from "lodash/get";
import { useSnackbar } from "notistack";
import { useDebugValue, useEffect, useMemo, useState } from "react";
import { formatUnits, minBigNumber, parseUnits } from "utils/number";
import { getPrimaryIndexTokenAtomicRepayment } from "utils/ethereum/contracts";

import {
  DECIMAL_SCALE,
  EVENT_TYPES,
  REPAY_MAX_BUFFER,
  REPAY_TOLERANCE_AMOUNT,
} from "app/constants";
import {
  DialogApplyButton,
  DialogLogo,
  HealthFactorProgressBar,
  NumericTextField,
  Spinner,
} from "components";

import BigNumber from "bignumber.js";
import { useBorrowContext } from "context/contracts/BorrowContext";
import * as ethers from "ethers";
import { useWallet } from "hooks";
import { useAtomicRepayment } from "hooks/atomic/useAtomicRepayment";
import { useGetPair } from "hooks/atomic/useGetPair";
import { useGetRemainingDeposit } from "hooks/atomic/useGetRemainingDeposit";
import { useGetTotalOutstanding } from "hooks/atomic/useGetTotalOutstanding";
import { useDebounce } from "hooks/common/useDebounce";
import { useLendingTokenMutations, useRouterMutations } from "hooks/mutation";
import { isEqualLowerString, isWrapNative, isZksyncNetwork } from "utils/addressUtils";
import { createBuyCallData } from "utils/ethereum/createBuyCallData";
import { calculateSafetyBuffer, convertNumberHex } from "utils/utils";

import { useStyles } from "./useStyles";

const RepayModal = ({ data: dataModal, onClose }) => {
  const {
    name,
    lendingToken: {
      address: lendingTokenAddress,
      decimal: lendingDecimal,
      price: lendingTokenPrice,
    },
    healthFactor,
    address,
    0: prjToken,
    data,
  } = dataModal;

  const { enqueueSnackbar } = useSnackbar();
  const { chainId, signer } = useWallet();
  const isZkSync = isZksyncNetwork(chainId);

  const context = useBorrowContext();
  const classes = useStyles();
  const [inputNum, setInputValue] = useState("");

  const inputValue = useDebounce(inputNum, 200);
  const [safetyBuffer, setSafetyBuffer] = useState(1 - 1 / healthFactor);
  const [amountSwap, setAmountSwap] = useState(0);
  const [priceDiff, setPriceDiff] = useState(0);
  const [allowance, userLendingTokenInfo] = useMemo(
    () => [
      _get(context, ["allowanceForFToken", lendingTokenAddress, "allowance"], false),
      _get(context, ["userTokenInfo"], []).find((token) =>
        isEqualLowerString(token.address, lendingTokenAddress)
      ),
    ],
    [context, lendingTokenAddress]
  );
  const userLendingBalance = userLendingTokenInfo ? userLendingTokenInfo.balanceOf : 0;
  const userLendingBalanceBN = parseUnits(userLendingBalance, lendingDecimal);

  const isFetching = useMemo(() => _get(context, ["isFetching"], false), [context]);
  const refetch = useMemo(() => _get(context, ["refetch"], () => {}), [context]);
  const fTokenRate = useMemo(
    () => _get(context, ["fTokenRate", lendingTokenAddress], {}),
    [context, lendingTokenAddress]
  );

  const { isLoading, repay, approve } = useLendingTokenMutations({
    name,
    amount: inputValue,
    kind: EVENT_TYPES.repay,
  });

  const { repayCollateral, isWaitingTx } = useAtomicRepayment();
  const { getAmountOut, getAmountIn, isLoadingData } = useRouterMutations();

  const remainingDepositValue = useGetRemainingDeposit(address);

  const outstanding = useGetTotalOutstanding(address, lendingTokenAddress);
  const outstandingFormat = formatUnits(outstanding || 0, lendingDecimal);

  const owedAmount = outstandingFormat;

  const maxRepayValue = useMemo(
    () => minBigNumber([outstanding, userLendingBalanceBN]),
    [outstanding, userLendingBalanceBN]
  );

  const maxRepayCollateralValue = useMemo(() => minBigNumber([outstanding]), [outstanding]);

  const maxRepayFormatedValue = useMemo(
    () => formatUnits(maxRepayValue || 0, lendingDecimal),
    [maxRepayValue, lendingDecimal]
  );

  const maxRepayCollateralFormatedValue = useMemo(
    () => formatUnits(maxRepayCollateralValue || 0, lendingDecimal),
    [maxRepayCollateralValue, lendingDecimal]
  );

  const pairToken = useGetPair(address, lendingTokenAddress);

  const isRepayFully = useMemo(
    () => new BigNumber(inputValue).minus(owedAmount).abs().lte(REPAY_TOLERANCE_AMOUNT),
    [inputValue, owedAmount]
  );

  const isRepayDisabled =
    !inputValue.toString().length ||
    !Number(inputValue) ||
    parseUnits(inputValue, lendingDecimal).gt(maxRepayValue);

  const isRepayCollateralDisabled =
    !inputValue.toString().length ||
    !Number(inputValue) ||
    Number(amountSwap) === 0 ||
    parseUnits(inputValue, lendingDecimal).gt(maxRepayCollateralValue);

  const isHavePairToken = useMemo(
    () =>
      pairToken &&
      !isEqualLowerString(pairToken, constants.AddressZero) &&
      !isEqualLowerString(address, constants.AddressZero),
    [address, pairToken]
  );

  const needAddAllowance = inputValue
    ? allowance.lt(ethers.utils.parseUnits(inputValue?.toString(), lendingDecimal)) &&
      !isWrapNative(lendingTokenAddress, chainId)
    : false;

  const isLoadingModal = useMemo(
    () => isLoading || isWaitingTx || isFetching,
    [isFetching, isLoading, isWaitingTx]
  );

  const resetInputValue = () => {
    setInputValue("");
  };

  const handleRepay = async () => {
    try {
      if (needAddAllowance) {
        await approve({ address: lendingTokenAddress, ftoken: _get(fTokenRate, "ftoken") });
        return;
      }
      const repayParams = {
        borrowToken: _get(dataModal, ["lendingToken", "address"], ""),
        prjAddress: address,
        prjAmount: isRepayFully
          ? constants.MaxUint256.toString()
          : parseUnits(convertNumberHex(inputValue), lendingDecimal),
        prjAmountPayable: isRepayFully
          ? outstanding
          : parseUnits(convertNumberHex(inputValue), lendingDecimal),
      };

      await repay(repayParams);
      await refetch();

      resetInputValue();
      onClose();
    } catch {
      resetInputValue();
    }
  };

  const handleRepayCollateral = async () => {
    try {
      if (!isHavePairToken) {
        enqueueSnackbar(`No pair token!`, { variant: "error", autoHideDuration: 5000 });
        return;
      }
      if (!+remainingDepositValue) {
        enqueueSnackbar(`No collateral amount!`, {
          variant: "error",
          autoHideDuration: 5000,
        });
        return;
      }
      if (!inputValue.toString().length || !Number(inputValue)) {
        enqueueSnackbar(`No input value!`, {
          variant: "error",
          autoHideDuration: 5000,
        });
        return;
      }

      let inputValueWithBuffer = inputValue;

      if (isRepayFully) {
        inputValueWithBuffer = new BigNumber(inputValue).multipliedBy(1 + REPAY_MAX_BUFFER);
      }

      const repayAmount = parseUnits(
        inputValueWithBuffer.toFixed(lendingDecimal) || "0",
        lendingDecimal
      );

      const collateralAmount = parseUnits(
        new BigNumber(amountSwap).toFixed(prjToken.decimal),
        prjToken.decimal
      );
      const { address: atomicRepaymentAddress } = getPrimaryIndexTokenAtomicRepayment(chainId);
      const buyCalldata = await createBuyCallData(
        { address, decimal: prjToken.decimal },
        collateralAmount,
        repayAmount,
        { address: lendingTokenAddress, decimal: lendingDecimal },
        { chainId, signer, account: atomicRepaymentAddress }
      );

      await repayCollateral({
        prjAddress: address,
        lendingAddress: lendingTokenAddress,
        collateralAmount,
        buyCalldata,
        isRepayFully,
      });

      onClose();
    } catch (error) {
      if (
        error.reason !== "execution reverted: UniswapV2Router: INSUFFICIENT_INPUT_AMOUNT" &&
        error.reason !== "execution reverted: AtomicRepayment: invalid amount" &&
        error.reason !== "execution reverted: UniswapV2: INSUFFICIENT_INPUT_AMOUNT" &&
        error.reason !== "user rejected transaction"
      ) {
        enqueueSnackbar("Repay using collateral failed!", {
          variant: "error",
          autoHideDuration: 5000,
        });
      }
    } finally {
      resetInputValue();
    }
  };

  const [logoLendingToken, lendingTokenName, lendingAsset] = useMemo(
    () => [
      _get(dataModal, ["lendingToken", "logo"], "./assets/coins_list/usd-coin.svg"),
      _get(dataModal, ["lendingToken", "name"], ""),
      _get(dataModal, ["lendingToken", "symbol"], ""),
    ],
    [dataModal]
  );

  const handleRepayMax = () => {
    setInputValue(maxRepayFormatedValue);
  };

  const handleRepayCollateralMax = async () => {
    setInputValue(maxRepayCollateralFormatedValue);
  };

  useEffect(() => {
    const fetchAmountOut = async () => {
      const repayAmount = new BigNumber(inputValue)
        .multipliedBy(1 + REPAY_MAX_BUFFER)
        .toFixed(lendingDecimal);
      let amountAfterSwap;
      if (isZkSync) {
        const { amountOut } = await getAmountOut({
          maxAmountIn: parseUnits(repayAmount, lendingDecimal),
          inputToken: lendingTokenAddress,
          outputToken: address,
          chainId,
          signer,
        });
        amountAfterSwap = amountOut * (1 + REPAY_MAX_BUFFER);
      } else {
        const { amountIn } = await getAmountIn({
          amountOut: parseUnits(repayAmount, lendingDecimal),
          inputToken: address,
          outputToken: lendingTokenAddress,
        });
        amountAfterSwap = amountIn * (1 + REPAY_MAX_BUFFER);
      }

      const amountFormatedAfterSwap = Number(
        formatUnits(amountAfterSwap.toFixed(0), prjToken.decimal)
      );
      const priceLendingToken = inputValue * lendingTokenPrice;
      const pricePrjToken = amountFormatedAfterSwap * prjToken.price;
      const priceDifference = ((pricePrjToken - priceLendingToken) / priceLendingToken) * 100;
      if (!Number.isNaN(priceDifference)) {
        setPriceDiff(priceDifference.toFixed(2));
      } else {
        setPriceDiff("0");
      }

      setAmountSwap(amountFormatedAfterSwap.toFixed(6));
    };

    fetchAmountOut();
  }, [
    address,
    getAmountIn,
    getAmountOut,
    inputValue,
    lendingDecimal,
    lendingTokenAddress,
    lendingTokenPrice,
    pairToken,
    prjToken.decimal,
    prjToken.price,
    chainId,
    isZkSync,
    signer,
  ]);

  useDebugValue(inputValue);

  useEffect(() => {
    if (inputValue) {
      const lvrDenominator = Number(data.pitCollateral.decimal);
      const lvrNumerator =
        (Number(data.totalOutstanding.decimal) - Number(inputValue)) * Number(lendingTokenPrice);

      const sb = 1 - lvrNumerator / lvrDenominator;

      if (sb >= 1) {
        setSafetyBuffer(1);
      } else if (sb <= 0) {
        setSafetyBuffer(0);
      } else {
        setSafetyBuffer(sb);
      }
    } else {
      const newSafetyBuffer = calculateSafetyBuffer(healthFactor);
      setSafetyBuffer(newSafetyBuffer);
    }
  }, [data, healthFactor, inputValue, lendingTokenPrice]);

  return (
    <>
      <DialogLogo logoUrl={logoLendingToken} name={lendingTokenName} />
      {isLoadingModal && <Spinner position="absolute" color="success" />}
      <Box pt={5} p={0} className={classes.rootContainer}>
        <NumericTextField
          value={inputNum}
          onChange={setInputValue}
          decimalScale={DECIMAL_SCALE}
          addressToken={lendingTokenAddress}
          decimalToken={lendingDecimal}
        />
        <Box px={2} pb={2} mt={2} pt={2} className={classes.contentInner}>
          <HealthFactorProgressBar value={safetyBuffer} isSafetyBuffer />
          <Box className={classes.maxRepay}>
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item md={6}>
                Owed
              </Grid>
              <Grid item>
                <Typography color="primary">{`${owedAmount} ${lendingAsset}`} </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box>
          <Grid container>
            <Grid item xs={9}>
              <DialogApplyButton disabled={isRepayDisabled} onClick={handleRepay}>
                {needAddAllowance ? "Enable" : "Repay"}
              </DialogApplyButton>
            </Grid>
            <Grid item xs={3}>
              <Button className={classes.maxButton} onClick={handleRepayMax}>
                MAX
              </Button>
            </Grid>
          </Grid>

          <Box marginTop={3}>
            <Box className={classes.collateralAmount}>
              <Grid container alignItems="center" justifyContent="space-between">
                <Grid item md={6}>
                  <Tooltip
                    title="The amount of collateral that needs to be swapped."
                    arrow
                    placement="top"
                  >
                    <Box display="inline">Collateral amount</Box>
                  </Tooltip>
                </Grid>
                <Grid item>
                  <Typography color="primary">{`${amountSwap} ${prjToken.symbol}`} </Typography>
                </Grid>
              </Grid>{" "}
              <Grid container alignItems="center" justifyContent="space-between">
                <Grid item md={6}>
                  <Tooltip
                    title="The estimated difference between the USD values of input and output amounts."
                    arrow
                    placement="top"
                  >
                    <Box display="inline">Price discrepancy</Box>
                  </Tooltip>
                </Grid>
                <Grid item>
                  <Typography color="primary">{priceDiff}% </Typography>
                </Grid>
              </Grid>
            </Box>
            <Grid container>
              <Grid item xs={9}>
                <DialogApplyButton
                  disabled={isRepayCollateralDisabled}
                  onClick={handleRepayCollateral}
                >
                  Repay using collateral
                </DialogApplyButton>
              </Grid>
              <Grid item xs={3}>
                <Button className={classes.maxButton} onClick={handleRepayCollateralMax}>
                  {isLoadingData ? (
                    <CircularProgress
                      className={classes.circularProgress}
                      size={16}
                      color="inherit"
                    />
                  ) : (
                    "MAX"
                  )}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default RepayModal;
