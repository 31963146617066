import {
  BUSDC_CONTRACT,
  LENDING_TOKEN_ADDRESS,
  PRICE_PROVIDER_AGGREGATOR_CONTRACT,
  PRIMARY_INDEX_TOKEN,
  REACT_APP_UNISWAPV2_FACTORY_ETHEREUM,
  REACT_APP_UNISWAPV2_ROUTER,
  ATOMIC_REPAYMENT_CONTRACT,
  REACT_APP_INSTANT_LEVERAGE,
  JUMP_RATE_MODEL,
  REACT_APP_MUTE_ROUTER,
  REACT_APP_MUTE_FACTORY,
  REACT_APP_PYTH_PRICE_PROVIDER,
  PRIMARY_LENDING_WRAPPED_TOKEN_GATEWAY,
} from "constants/NetworkChainId";
import {
  bUSDCContractABI,
  PriceProviderAggregatorABI,
  PrimaryIndexTokenABI,
  AtomicRepaymentAbi,
  UniswapV2FactoryAbi,
  UniswapV2RouterAbi,
  InstantLeverageAbi,
  PriceProviderAggregatorZksyncABI,
  PrimaryIndexTokenZksyncABI,
  AtomicRepaymentZksyncAbi,
  InstantLeverageZkSyncAbi,
  JumpRateModelABI,
  MuteRouterABI,
  MuteFactoryABI,
  PrimaryLendingWrappedTokenGatewayABI,
} from "./abi";

export const getPrimaryIndexToken = (networkId) => ({
  address: PRIMARY_INDEX_TOKEN[networkId],
  abi: PrimaryIndexTokenABI,
});

export const getPrimaryIndexTokenZksync = (networkId) => ({
  address: PRIMARY_INDEX_TOKEN[networkId],
  abi: PrimaryIndexTokenZksyncABI,
});

export const bUSDCContract = (networkId) => ({
  address: BUSDC_CONTRACT[networkId],
  abi: bUSDCContractABI,
});

export const getPriceProviderAggregatorContract = (networkId) => ({
  address: PRICE_PROVIDER_AGGREGATOR_CONTRACT[networkId],
  abi: PriceProviderAggregatorABI,
});

export const getPriceProviderAggregatorZksyncContract = (networkId) => ({
  address: PRICE_PROVIDER_AGGREGATOR_CONTRACT[networkId],
  abi: PriceProviderAggregatorZksyncABI,
});

export const getPrimaryIndexTokenAtomicRepayment = (networkId) => ({
  address: ATOMIC_REPAYMENT_CONTRACT[networkId],
  abi: AtomicRepaymentAbi,
});

export const getPrimaryIndexTokenAtomicRepaymentZksync = (networkId) => ({
  address: ATOMIC_REPAYMENT_CONTRACT[networkId],
  abi: AtomicRepaymentZksyncAbi,
});

export const getUniswapV2Factory = (networkId) => ({
  address: REACT_APP_UNISWAPV2_FACTORY_ETHEREUM[networkId],
  abi: UniswapV2FactoryAbi,
});

export const getUniswapV2Router = (networkId) => ({
  address: REACT_APP_UNISWAPV2_ROUTER[networkId],
  abi: UniswapV2RouterAbi,
});

export const getInstantLeverageContract = (networkId) => ({
  address: REACT_APP_INSTANT_LEVERAGE[networkId],
  abi: InstantLeverageAbi,
});

export const getInstantLeverageZkSyncContract = (networkId) => ({
  address: REACT_APP_INSTANT_LEVERAGE[networkId],
  abi: InstantLeverageZkSyncAbi,
});

export const lendingTokenAddress = (networkId) => LENDING_TOKEN_ADDRESS[networkId || 5];

export const getJumpRateModel = (networkId) => ({
  address: JUMP_RATE_MODEL[networkId],
  abi: JumpRateModelABI,
});

export const getMuteFactory = (networkId) => ({
  address: REACT_APP_MUTE_FACTORY[networkId],
  abi: MuteFactoryABI,
});

export const getMuteRouter = (networkId) => ({
  address: REACT_APP_MUTE_ROUTER[networkId],
  abi: MuteRouterABI,
});

export const getPythProvider = (networkId) => ({
  address: REACT_APP_PYTH_PRICE_PROVIDER[networkId] || "",
});

export const getPrimaryLendingPlatformWrappedTokenGateway = (networkId) => ({
  address: PRIMARY_LENDING_WRAPPED_TOKEN_GATEWAY[networkId],
  abi: PrimaryLendingWrappedTokenGatewayABI,
});
