import { useSnackbar } from "notistack";
import { useCallback, useMemo } from "react";
import ReactGa from "react-ga";
import { useMutation, useQueryClient } from "react-query";

import { useEventContractAction } from "context/EventContract/EventContractProvider";
import { useWallet } from "hooks";
import { useERC20TokenApproval } from "hooks/contract/tokens/useERC20TokenApproval";
import { usePITContract } from "hooks/contract/usePITContract";
import { PIT_EVENT } from "utils/ethereum/pitEventConstant";
import { usePITWrappedTokenGatewayContract } from "hooks/contract/usePITWrappedTokenGatewayContract";
import { isWrapNative } from "utils/addressUtils";

const useCoinMutations = ({ projectTokenAddress }) => {
  const queryClient = useQueryClient();
  const { onEvent } = useEventContractAction();
  const { account, chainId } = useWallet();
  const { enqueueSnackbar } = useSnackbar();
  const {
    callback: { depositCall: depositERC20Call, withdrawCall: withdrawERC20Call },
    data: { PitInstance },
  } = usePITContract();

  const {
    callback: { depositCall: depositETHCall, withdrawCall: withdrawETHCall },
  } = usePITWrappedTokenGatewayContract();

  const isWrappedNative = useMemo(
    () => isWrapNative(projectTokenAddress, chainId),
    [chainId, projectTokenAddress]
  );

  const { approve: approvePIT, isLoading } = useERC20TokenApproval(projectTokenAddress, [
    PitInstance.address,
  ]);

  const handleError = useCallback(
    (error) => {
      onEvent(null);
      if (error.message === "userRejectedRequest") {
        enqueueSnackbar("Transaction has been canceled", {
          variant: "warning",
          autoHideDuration: 5000,
        });
      }
      if (error.message.includes("user rejected transaction")) {
        enqueueSnackbar(`Transaction rejected: user rejected transaction`, {
          variant: "error",
          autoHideDuration: 5000,
        });
      } else {
        enqueueSnackbar(`Transaction rejected: ${error.message}`, {
          variant: "error",
          autoHideDuration: 5000,
        });
      }
    },
    [enqueueSnackbar, onEvent]
  );

  const deposit = useMutation(
    async (value) => {
      if (isWrappedNative) {
        await depositETHCall({ collateralAmount: value });
      } else {
        await depositERC20Call({ collateralAddress: projectTokenAddress, collateralAmount: value });
      }
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["available-multicall", account]);
        await queryClient.invalidateQueries(["borrowed-pit-multicall", account]);
        onEvent(PIT_EVENT.Deposit);

        enqueueSnackbar("The transaction has been confirmed successfully!", {
          variant: "success",
          autoHideDuration: 5000,
        });

        ReactGa.event({
          category: "Borrower Dashboard",
          action: "Deposit success",
        });
      },
      onError: handleError,
    }
  );

  const withdraw = useMutation(
    async ({ value, lendingAddress }) => {
      if (isWrappedNative) {
        await withdrawETHCall({
          wrappedETHAddress: projectTokenAddress,
          collateralAmount: value,
          lendingAddress,
        });
      } else {
        await withdrawERC20Call({
          collateralAddress: projectTokenAddress,
          collateralAmount: value,
          lendingAddress,
        });
      }
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["available-multicall", account]);
        await queryClient.invalidateQueries(["borrowed-pit-multicall", account]);
        onEvent(PIT_EVENT.Withdraw);

        enqueueSnackbar("The transaction has been confirmed successfully!", {
          variant: "success",
          autoHideDuration: 5000,
        });

        ReactGa.event({
          category: "Borrower Dashboard",
          action: "Withdraw success",
        });
      },
      onError: handleError,
    }
  );

  return {
    isLoading: isLoading || deposit.isLoading || withdraw.isLoading,
    approve: approvePIT,
    deposit: deposit.mutateAsync,
    withdraw: withdraw.mutateAsync,
  };
};

export default useCoinMutations;
