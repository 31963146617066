import { BorrowContextProvider } from "context/contracts/BorrowContextProvider";
import { useParams } from "react-router-dom";
import MetaTags from "react-meta-tags";
import { OpenGraph } from "components/AssetLanding/OpenGraph";
import { useMemo } from "react";
import coinList from "assets/coin-list.json";
import { useWallet } from "hooks";
import { defaultNetwork, wrapNativeToken } from "utils/addressUtils";
import coinInfo from "assets/coin-landing.json";
import Custom404 from "components/AssetLanding/Custom404";
import { useQuery as useClientQuery } from "@apollo/client";
import { GET_PRJ_TOKEN_WITHOUT_ACCOUNT } from "pages/BorrowerNewDashboard/hooks/useTokenSupported";
import { get } from "lodash";
import AssetLandingPage from "./AssetLandingPage";

const AssetLanding = () => {
  const { symbol } = useParams();
  const { chainId, connected } = useWallet();
  const activeChainId = useMemo(
    () => (!connected ? defaultNetwork.id.toString(16) : parseInt(chainId, 16)),
    [chainId, connected]
  );
  const { data, loading } = useClientQuery(GET_PRJ_TOKEN_WITHOUT_ACCOUNT);
  const tokens = useMemo(() => {
    const projectTokens = get(data, "projectTokens", []);
    const lendingTokens = get(data, "lendingTokens", []);
    const addresses = Array.from(
      new Set([...projectTokens, ...lendingTokens].map((token) => token.address))
    );
    return addresses;
  }, [data]);

  const { address, coin } = useMemo(() => {
    const nativeCoin = wrapNativeToken(`0x${activeChainId.toString(16)}`);
    if (nativeCoin.symbol === symbol) {
      return { address: nativeCoin.address, coin: nativeCoin };
    }
    const coins = coinList[activeChainId] || {};
    const tokenAddress = Object.keys(coins).find((add) => coins[add].symbol === symbol);
    return {
      address: tokenAddress,
      coin: coins[tokenAddress],
    };
  }, [activeChainId, symbol]);

  const isListed = useMemo(
    () => !!tokens.find((tokenAddress) => tokenAddress?.toLowerCase() === address?.toLowerCase()),
    [address, tokens]
  );

  const coinDescription = useMemo(() => {
    const coins = coinInfo?.[activeChainId];
    if (!coins?.[symbol]) {
      return { description: "", lendingMarketLogo: null };
    }
    const description = coins[symbol]?.description;
    const lendingMarketLogo = coins[symbol]?.logo;
    return {
      description,
      lendingMarketLogo,
      ...coins[symbol],
      ...coin,
    };
  }, [activeChainId, coin, symbol]);
  return isListed ? (
    <BorrowContextProvider>
      <MetaTags>
        <title>Lend out, borrow against and margin trade your {coinDescription.name}</title>
        <meta
          name="description"
          content="Optimize your capital by borrowing against or lending out your assets or using them to open margin trade positions on Fringe Finance, the decentralized lending, borrowing, and margin trade platform for assets of all kinds."
        />
        <OpenGraph
          siteName="Fringe"
          type="article"
          description={coinDescription.description}
          image={coinDescription.lendingMarketLogo}
          url={`https://fringe.fi/borrowing/${symbol}`}
          title={coinDescription.title}
        />
      </MetaTags>
      <AssetLandingPage
        address={address}
        description={coinDescription.description}
        lendingMarketLogo={coinDescription.lendingMarketLogo}
      />
    </BorrowContextProvider>
  ) : (
    !loading && <Custom404 />
  );
};

export default AssetLanding;
