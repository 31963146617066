import { Contract } from "ethers";
import React from "react";

import useWallet from "hooks/useWallet";
import { getUniswapV2Factory } from "utils/ethereum/contracts";

export const useUniswapV2FactoryContract = (signer) => {
  const { chainId } = useWallet();

  const uniswapV2Factory = React.useMemo(() => {
    if (!signer) return null;
    const contractInfo = getUniswapV2Factory(chainId);
    if (!contractInfo.address) {
      return null;
    }
    const smc = new Contract(contractInfo?.address, contractInfo?.abi, signer);
    return smc;
  }, [chainId, signer]);

  return { uniswapV2Factory };
};
