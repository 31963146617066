import { ethers } from "ethers";
import { groupBy } from "lodash";

/**
 * Use to decode data from multicall in ZkSync
 * @param {Array} request -
 * - Array of request to aggregate multicall
 * - Array request must have methodName, contract, methodParameters, reference, contractName
 * @param {*} resultFromMulticall - Result returned from multicall after aggregate
 * @returns {Object} - Returns the pair key-value group by contractName
 */
export function decodeResultMulticallZkSync(request = [], resultFromMulticall) {
  const getDecodeResults = request.map((data, idx) => {
    const { methodName, contract, methodParameters, reference, contractName } = data;
    const resultAtIndex = resultFromMulticall[idx];

    let decodeValue;

    try {
      decodeValue = contract.interface.decodeFunctionResult(methodName, resultAtIndex);
    } catch (e) {
      if (methodName === "name" || methodName === "symbol") {
        decodeValue = ethers.utils.parseBytes32String(resultAtIndex);
      } else {
        throw e;
      }
    }

    return {
      methodName,
      methodParameters,
      reference,
      returnValues: decodeValue,
      contractName,
      contract,
    };
  });

  const results = groupBy(getDecodeResults, "contractName");

  return results;
}

/**
 *
 * @param {*} resultFromMulticall
 * @returns {Array} - Return array of returnData from multicall
 */
export function formatMulticallResults(resultFromMulticall = []) {
  const returnData = [];
  resultFromMulticall.forEach((rs) => {
    returnData.push(rs.returnData);
  });

  return returnData;
}
