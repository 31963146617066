import { usePITContractAction } from "hooks/contract/core/usePITContractAction";
import useWallet from "hooks/useWallet";
import { useQuery } from "react-query";

export const useGetLVR = (prjToken, lendingToken) => {
  const { account, chainId } = useWallet();
  const { PITContract } = usePITContractAction();

  const getLVR = useQuery(
    ["get-loan-to-value-ratio", account, chainId, prjToken, lendingToken],
    async () => {
      const res = await PITContract.getLoanToValueRatio(prjToken, lendingToken);

      return res;
    },
    {
      enabled: !!(prjToken && lendingToken),
    }
  );

  return getLVR.data;
};
