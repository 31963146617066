import { gql, useQuery as useClientQuery } from "@apollo/client";
import { useAccount, useChainId, useProvider } from "wagmi";
import { useQuery } from "react-query";
import { getPriceProviderAggregatorContract } from "utils/ethereum/contracts";
import { useMultiCallContractInstance } from "hooks/contract/multicall/useMultiCallContract";
import handleGetListTokenProvider from "utils/ethereum/handleGetListTokenProvider";
import { ProjectTokenContext } from "./ProjectTokenContext";

const GET_PRJ_TOKEN = gql`
  query ExampleQuery($account: String!) {
    projectTokens {
      name
      symbol
      address
      isDepositPaused
      isWithdrawPaused
      underlyingTokens {
        id
        name
        symbol
        address
        linksNumber
      }
      depositedAmount
      lvr
      pitAmount
      borrowedAmount
      outstandingAmount
      borrowingLevelAmount
      currentBorrowingLevel
      __typename
    }

    totalStates {
      type
      amount
      lendingTokenAddress
    }

    lendingTokens {
      name
      symbol
      address
      isPaused
    }
    borrowLogs(where: { userAddress: $account }, orderBy: date, orderDirection: desc) {
      id
      amount
      asset
      prjToken
      type
      date
      userAddress
      prjTokenAddress
    }
  }
`;

export const ProjectTokenProvider = ({ children }) => {
  const { address } = useAccount();
  const chainId = useChainId();
  const provider = useProvider();
  const multiCallContract = useMultiCallContractInstance();

  const resp = useClientQuery(GET_PRJ_TOKEN, {
    variables: { account: address },
    fetchPolicy: "cache-first",
    nextFetchPolicy: "cache-first",
  });

  // prepare price provider
  const resp2 = useQuery(
    async () => {
      const listToken = Array.from(
        new Set(
          [
            ...resp.data.lendingTokens,
            ...resp.data.projectTokens,
            // eslint-disable-next-line no-shadow
          ].map((x) => x.address)
          // eslint-disable-next-line no-shadow
        )
      ).map((addr) => ({ address: addr }));

      const PriceContractInfo = getPriceProviderAggregatorContract(chainId);
      await handleGetListTokenProvider(
        listToken,
        PriceContractInfo,
        provider,
        multiCallContract,
        chainId,
        true
      );
    },
    {
      enabled: !resp.loading && resp.data,
    }
  );

  return (
    <ProjectTokenContext.Provider
      value={{
        ...resp,
        loading: resp.loading || resp2.isLoading,
      }}
    >
      {children}
    </ProjectTokenContext.Provider>
  );
};
