import { BigNumber } from "ethers";
import { formatUnits } from "utils/number";
import { groupBy, get, omit, map } from "lodash";

export const getLvrFromResult = (results, prjTokenAddress) => {
  const projectTokenInfo = groupBy(
    get(groupBy(get(results, ["PITContract"]), "methodName"), "projectTokenInfo"),
    "reference"
  );

  const lvrNumerator = get(projectTokenInfo, [prjTokenAddress, 0, "returnValues", 3, 0], 0);
  const lvrDenominator = get(projectTokenInfo, [prjTokenAddress, 0, "returnValues", 3, 1], 1);

  return lvrNumerator / (lvrDenominator || 1);
};

export const handleGetPriceInUsd = (results, key) => {
  const priceResults = groupBy(get(results, ["PriceContract"]), "reference");

  const priceTokenBN = get(priceResults, [key, 0, "returnValues", 0], 1);
  const priceDecimal = +get(priceResults, [key, 0, "returnValues", 1], 0);
  return {
    priceTokenBN,
    priceDecimal,
  };
};

export const handleGetDepositTokens = ({ results, listToken, isMainNet = false }) => {
  const list = [];
  let uniswapV2Factory;

  if (isMainNet) {
    uniswapV2Factory = get(results, "UniswapV2FactoryContract");
  } else {
    uniswapV2Factory = get(results, ["UniswapV2FactoryContract"]);
  }

  map(omit(results, ["PriceContract", "PITContract", "UniswapV2FactoryContract"]), (value, key) => {
    const tokenInfo = listToken.find((o) => o.address === key);
    if (!tokenInfo) return;

    const group = groupBy(value, (x) => x.reference);

    const pairToken = isMainNet
      ? {}
      : uniswapV2Factory
          .filter((i) => get(i, ["methodParameters", 0]) === key)
          .reduce((prev, cur) => {
            const lendingToken = get(cur, ["methodParameters", 1]);
            const pairValue = get(cur, ["returnValues", 0]);

            return { ...prev, [lendingToken]: pairValue };
          }, {});

    const decimal = +get(group, ["decimal", 0, "returnValues", 0], "0");

    const allowance = +get(group, ["isAllowanceForPIT", 0, "returnValues", 0], "0");

    const balanceBN = get(group, ["balanceOfWallet", 0, "returnValues", 0], "0");
    const balanceOfWallet = formatUnits(balanceBN, decimal);

    const { priceTokenBN, priceDecimal } = handleGetPriceInUsd(results, key);

    const balanceInUsd = formatUnits(
      BigNumber.from(balanceBN).mul(priceTokenBN),
      priceDecimal + decimal
    );

    const lvr = getLvrFromResult(results, key);

    list.push({
      ...tokenInfo,
      balance: balanceOfWallet,
      allowance,
      healthFactor: 5.999994000006,
      lvr,
      price: balanceInUsd,
      pairToken,
    });
  });

  return list;
};
