import BigNumber from "bignumber.js";
import { USD_DECIMAL } from "constants/contract";
import { constants } from "ethers";
import _ from "lodash";
import { formatUnits } from "utils/number";
import { getLimitBorrowingOfLendingTokenFromMultiData } from "./getLimitBorrowingOfLendingTokenFromMultiData";
import { handleEvaluation } from "./handleEvaluation";
import { handleGetDataFromPosition } from "./handleGetDataFromPosition";
import { handleGetHealthFactor } from "./handleGetHealthFactor";
import { handleGetIssuedPITAmount } from "./handleGetIssuedPITAmount";
import { handleGetLiquidationThreshold } from "./handleGetLiquidationThreshold";
import { handleGetPITRemainingAmount } from "./handleGetPITRemainingAmount";
import { handleLvrLending } from "./handleLvrLending";
import methodOfPITContract, { methodOfPitContractZksync } from "./methodOfPitContract";

const { lendingTokenInfoMethod, getLendingTokenMethod } = methodOfPITContract;
const {
  pitCollateral,
  getTokenEvaluationWithUpdatePricesMethod,
  getLendingAvailableToBorrowWithUpdatePricesMethod,

  getTotalBorrowPerLendingToken,
  getPosition,
  pitRemaining,
} = methodOfPitContractZksync;

export function handleGetDataForBorrowContextZkSync(resultMultiCall, isMainNet) {
  const pitContract = _.get(resultMultiCall, "PITContract");
  const UniswapV2FactoryContract = _.get(resultMultiCall, "UniswapV2FactoryContract");

  const pairToken = isMainNet
    ? {}
    : UniswapV2FactoryContract.reduce((prev, cur) => {
        const lendingToken = _.get(cur, ["methodParameters", 0]);
        const pairValue = _.get(cur, ["returnValues", 0]);

        return { ...prev, [lendingToken]: pairValue };
      }, {});

  const dataOnPitContract = _.groupBy(pitContract, "methodName");

  const liquidationThresholdList = handleGetLiquidationThreshold(
    _.get(dataOnPitContract, methodOfPITContract.liquidationThreshold, {})
  );

  const fTokenAddresses = _.get(dataOnPitContract, lendingTokenInfoMethod, []).map((o) => ({
    ftoken: _.get(o, ["returnValues", 2]),
    isPaused: _.get(o, ["returnValues", 1]),
    token: _.get(o, ["methodParameters", 0]),
  }));

  const lendingTokenInfo = _.get(dataOnPitContract, [lendingTokenInfoMethod]);
  const lvrLending = handleLvrLending(lendingTokenInfo);

  const pitRemainingList = _.get(dataOnPitContract, [pitRemaining]);
  const pitCollateralList = _.get(dataOnPitContract, [pitCollateral]);
  const positionData = _.get(dataOnPitContract, [getPosition]);

  const evaluationList = _.get(dataOnPitContract, [getTokenEvaluationWithUpdatePricesMethod]);
  const evaluations = handleEvaluation(evaluationList);

  const depositedAssets = handleGetDataFromPosition(positionData, 0);
  const totalOutstandingAssets = handleGetDataFromPosition(positionData, 1);
  const accrualAssets = handleGetDataFromPosition(positionData, 2);

  const totalPITCollateral = handleGetIssuedPITAmount(
    pitCollateralList,
    pitRemainingList,
    totalOutstandingAssets
  );

  const healthFactor = handleGetHealthFactor(positionData);

  const getLendingAvailableToBorrowList = _.get(dataOnPitContract, [
    getLendingAvailableToBorrowWithUpdatePricesMethod,
  ]);

  const pitRemainingX = handleGetPITRemainingAmount(getLendingAvailableToBorrowList);

  const limitBorrowInLendingToken = getLimitBorrowingOfLendingTokenFromMultiData(dataOnPitContract);

  const totalBorrowedInLendingToken = _.get(dataOnPitContract, [
    getTotalBorrowPerLendingToken,
  ])?.reduce(
    (res, resultCall) => ({
      ...res,
      [_.get(resultCall, ["reference"], constants.AddressZero)]: formatUnits(
        _.get(resultCall, ["returnValues", 0], "0"),
        USD_DECIMAL
      ),
    }),
    {}
  );

  const currentLimitLendingToken = _.reduce(
    limitBorrowInLendingToken,
    (res, limit, key) => ({
      ...res,
      [key]: {
        limitMax: limit,
        totalBorrowed: _.get(totalBorrowedInLendingToken, [key], "0"),
        limit: new BigNumber(limit)
          .minus(_.get(totalBorrowedInLendingToken, [key], "0"))
          .toString(),
      },
    }),
    {}
  );

  const getLendingToken = _.get(dataOnPitContract, [getLendingTokenMethod]);
  const borrwedList = _.reduce(
    getLendingToken,
    (prev, cur) => ({
      ...prev,
      [cur.methodParameters[1]]: cur.returnValues[0],
    }),
    {}
  );

  return {
    currentLimitLendingToken,
    currentLimitPrjToken: {},
    pitRemaining: pitRemainingX,
    totalPITCollateral,
    totalOutstandingAssets,
    accrualAssets,
    healthFactor,
    depositedAssets,
    fTokenAddresses,
    lvrLending,
    liquidationThresholdList,
    evaluations,
    borrwedList,
    pairToken,
  };
}
