export default {
  lendingTokenInfoMethod: "lendingTokenInfo",
  limitBorrowInCollateralMethod: "borrowLimitPerCollateral",
  limitBorrowInLendingTokenMethod: "borrowLimitPerLendingToken",
  pitRemainingMethod: "pitRemaining",
  pitCollateralMethod: "pitCollateral",
  positionMethod: "getPosition",
  totalBorrowedInCollateralMethod: "getTotalBorrowPerCollateral",
  totalBorrowedInLendingTokenMethod: "getTotalBorrowPerLendingToken",
  tokenEvaluationMethod: "getTokenEvaluation",
  getCollateralAvailableToWithdrawMethod: "getCollateralAvailableToWithdraw",
  getLendingAvailableToBorrowMethod: "getLendingAvailableToBorrow",
  getLendingTokenMethod: "getLendingToken",
};

export const methodOfPitContractZksync = {
  getCollateralAvailableToWithdrawWithUpdatePricesMethod:
    "getCollateralAvailableToWithdrawWithUpdatePrices",
  getLendingAvailableToBorrowWithUpdatePricesMethod: "getLendingAvailableToBorrowWithUpdatePrices",
  getPositionWithUpdatePricesMethod: "getPositionWithUpdatePrices",
  pitRemainingWithUpdatePricesMethod: "pitRemainingWithUpdatePrices",
  pitCollateralWithUpdatePricesMethod: "pitCollateralWithUpdatePrices",
  pitCollateral: "pitCollateral",
  getTotalBorrowPerCollateralWithUpdatePricesMethod: "getTotalBorrowPerCollateralWithUpdatePrices",
  getTokenEvaluationWithUpdatePricesMethod: "getTokenEvaluationWithUpdatePrices",
  getTotalBorrowPerLendingTokenWithUpdatePricesMethod:
    "getTotalBorrowPerLendingTokenWithUpdatePrices",

  getTokenEvaluation: "getTokenEvaluation",
  getPosition: "getPosition",
  pitRemaining: "pitRemaining",
  getTotalBorrowPerLendingToken: "getTotalBorrowPerLendingToken",
};
