import { get as _get, groupBy as _groupBy } from "lodash";
import { getLogoBySymbolAndName, getSpecialTokenSymbol } from "utils/utils";

export const mappingToGetProjectList = (data) =>
  _get(data, ["projectTokens"], []).map((o) => ({
    ...o,
    type: "projectToken",
    logo: getLogoBySymbolAndName(o.symbol, o.name, o?.address) || "/assets/coins_list/prjIcon.png",
  }));

export const groupingBorrowLogs = (data) => _groupBy(_get(data, ["borrowLogs"]), "prjTokenAddress");

export const groupingTotalStates = (data) => _groupBy(_get(data, ["totalStates"]), "type");

export const mappingToGetLendingToken = (data, chainId) =>
  _get(data, ["lendingTokens"], []).map((o) => ({
    ...o,
    type: "lendingToken",
    logo: getLogoBySymbolAndName(o.symbol, o.name, o?.address),
    symbol: getSpecialTokenSymbol(o.address, chainId) || o.symbol,
  }));
