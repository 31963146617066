import WalletContext from "context/Wallet/WalletContext";
import { useContext } from "react";
import { useAccount, useNetwork, useProvider, useSigner, useBalance } from "wagmi";

const useWallet = () => {
  const {
    isWrongChain,
    onSwitchChain,
    onCloseSwitchChain,
    isConnecting,
    onConnecting,
    onCloseConnect,
  } = useContext(WalletContext);

  const { address, isConnectingAccount, isConnected } = useAccount();

  const { data: signer, isLoading } = useSigner();
  const { chain } = useNetwork();

  const provider = useProvider({
    chainId: chain?.id,
  });

  const isNetworkUnsupported = chain?.unsupported;

  const { refetch } = useBalance({ address, chainId: chain?.id });

  return {
    connected: isConnected,
    connecting: isConnectingAccount || isLoading,
    account: address,
    fetchNativeBalance: refetch,
    chainId: `0x${chain?.id?.toString(16) || "0"}`,
    isNetworkUnsupported,
    provider,
    signer,
    isWrongChain,
    onSwitchChain,
    onCloseSwitchChain,
    isConnecting,
    onConnecting,
    onCloseConnect,
  };
};

export default useWallet;
