import { useMemo } from "react";
import PropTypes from "prop-types";
import CoinSummary from "components/AssetLanding/CoinSummary";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import CoinDescription from "components/AssetLanding/CoinDescription";
import CoinStats from "components/AssetLanding/CoinStats";
import { useTokenInfo, useBlendingTokenInfo, usePriceToken } from "hooks/contract/useTokenStats";
import { getLogoBySymbolAndName } from "utils/utils";
import { useBorrowContext } from "context/contracts/BorrowContext";

const useStyles = makeStyles((theme) => ({
  coinSummary: {
    marginLeft: "auto",
    marginRight: "auto",
    borderTop: "2px solid #6e6e6e",
    borderBottom: "2px solid #6e6e6e",
  },
  coinDescription: {
    marginTop: 50,
  },
  coinStats: {
    marginTop: 30,
    borderTop: "1px solid #2B2B2B",
  },
  path: {
    color: "#ffffff",
    paddingLeft: "15%",
    paddingBottom: 10,
    fontSize: "1.25rem",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
    },
  },
}));

const AssetLandingPage = ({ address, description, lendingMarketLogo }) => {
  const classes = useStyles();

  const {
    priceProvider,
    debtLimitUds,
    lvr,
    name,
    symbol,
    loading: loading1,
  } = useTokenInfo(address);
  const {
    totalSupplyUsd,
    totalBorrowsUsd,
    borrowApy,
    lenderApy,
    loading: loading2,
  } = useBlendingTokenInfo(address);
  const { price: tokenPrice } = usePriceToken(address, priceProvider);
  const { priceOfTokens: listTokenPrice } = useBorrowContext();

  const price = useMemo(() => {
    if (!address) return null;
    if (tokenPrice) {
      return tokenPrice;
    }
    if (listTokenPrice) {
      const key = Object.keys(listTokenPrice).find(
        (k) => k.toLowerCase() === address.toLowerCase()
      );
      return listTokenPrice[key];
    }
    return null;
  }, [address, listTokenPrice, tokenPrice]);

  const logo = useMemo(
    () => getLogoBySymbolAndName(symbol, name, address),
    [name, symbol, address]
  );

  return (
    <>
      <div>
        <Box className={classes.path}>
          {"Market > "} {symbol}
        </Box>
      </div>
      <div className={classes.coinSummary}>
        <CoinSummary
          logo={logo}
          symbol={symbol}
          name={name}
          lendingAPY={lenderApy}
          borrowingAPY={borrowApy}
          isLoading={loading1 !== false || loading2 !== false || !price}
          price={price}
        />
      </div>
      <div className={classes.coinDescription}>
        <CoinDescription
          description={description}
          name={name}
          symbol={symbol}
          logo={lendingMarketLogo || logo}
        />
      </div>
      <div className={classes.coinStats}>
        <CoinStats
          name={name}
          symbol={symbol}
          debtLimitUds={debtLimitUds}
          totalBorrowedUds={totalBorrowsUsd}
          totalSuppliedUds={totalSupplyUsd}
          price={price}
          lvr={lvr}
          utilization={totalBorrowsUsd / totalSupplyUsd}
          isLoading={!!loading1 || !!loading2 || !price}
        />
      </div>
    </>
  );
};

AssetLandingPage.propTypes = {
  lendingMarketLogo: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
};

export default AssetLandingPage;
