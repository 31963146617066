import { REACT_APP_ACCOUNT_HAVING_ETH } from "constants/NetworkChainId";
import { BigNumber, Contract } from "ethers";
import {
  decodeResultMulticallZkSync,
  formatMulticallResults,
} from "utils/contract/decodeResultMulticall";

const cacheGetListProvider = {};

/**
 * Get each token provider in list token array.
 * @param {Array} listToken - Array of token (each token must have a property `address`)
 * @param {Contract} PriceContractInfo - Info of price contract (must have a property `address` and `abi`)
 * @param {Multicall} provider
 * @return {(Object)} Return the object with `[key]:value` where key is a token address and value is an address of price provider
 */
export default async function handleGetListTokenProvider(
  listToken = [],
  PriceContractInfo,
  provider,
  multicallContract,
  chainId,
  invalidCache = false
) {
  // result from cache
  // invalidate
  if (invalidCache) cacheGetListProvider[chainId] = {};

  const priceProviderList = cacheGetListProvider[chainId] ? cacheGetListProvider[chainId] : {};

  if (listToken.length === 0) return {};

  const PriceContract = new Contract(PriceContractInfo.address, PriceContractInfo.abi, provider);

  // reduce, remove already fetched
  const listRequestToGetPythProvider = listToken
    .filter((token) => !priceProviderList[token.address])
    .map((token) => ({
      target: PriceContract.address,
      callData: PriceContract.interface.encodeFunctionData("tokenPriceProvider", [token.address]),
      reference: token.symbol,
      methodName: "tokenPriceProvider",
      methodParameters: [token.address],
      value: BigNumber.from(0),
      contract: PriceContract,
      contractName: "PriceContractTokenProvider",
    }));

  if (listRequestToGetPythProvider.length) {
    const valueOfRequest = listRequestToGetPythProvider.map((r) =>
      "value" in r ? r.value : BigNumber.from(0)
    );
    const totalValue = valueOfRequest.reduce((pre, cur) => pre.add(cur), BigNumber.from(0));

    const resultMulticall = await multicallContract.callStatic.aggregate3Value(
      listRequestToGetPythProvider,
      {
        value: totalValue,
        from: REACT_APP_ACCOUNT_HAVING_ETH[chainId],
      }
    ).catch(error => {
      console.log("Request multicall fail", listRequestToGetPythProvider);
      throw error
    });

    const returnData = formatMulticallResults(resultMulticall);
    const results = decodeResultMulticallZkSync(listRequestToGetPythProvider, returnData);

    const newPriceProviderList = results.PriceContractTokenProvider.reduce((prev, curr) => {
      const key = curr.methodParameters[0];
      const priceProvider = curr.returnValues[0];

      return { ...prev, [key]: priceProvider };
    }, {});

    // update cache
    const cached = {
      ...priceProviderList,
      ...newPriceProviderList,
    };
    cacheGetListProvider[chainId] = cached;
    return cached;
  }

  return priceProviderList;
}
