import useWallet from "hooks/useWallet";
import { useCallback, useMemo } from "react";
import { getPrimaryLendingPlatformWrappedTokenGateway } from "utils/ethereum/contracts";
import { useContract } from "wagmi";
import { getDataToUpdatePrice } from "utils/ethereum/getDataToUpdatePrice";
import { useBorrowContext } from "context/contracts/BorrowContext";
import * as _ from "lodash";
import { wrapNativeToken, ZERO_ADDRESS, isEqualLowerString } from "utils/addressUtils";
import { getTotalBorrow } from "utils/ethereum/getTotalBorrow";
import { usePriceContract } from "./core/usePriceContract";
import { usePITContract } from "./usePITContract";

export const usePITWrappedTokenGatewayContract = () => {
  const { chainId, signer } = useWallet();
  const GatewayInstance = useMemo(
    () => getPrimaryLendingPlatformWrappedTokenGateway(chainId),
    [chainId]
  );
  const {
    data: { PitContract },
  } = usePITContract();
  const context = useBorrowContext();
  const lendingTokenList = _.get(context, "availableBorrowTokens", []);

  const { PriceContract } = usePriceContract();

  const contract = useContract({
    address: GatewayInstance?.address,
    abi: GatewayInstance?.abi,
    signerOrProvider: signer,
  });

  const depositCall = useCallback(
    async ({ collateralAmount }) => {
      if (!contract) return;

      const callTx = await contract.deposit({ value: collateralAmount });

      await callTx.wait();
    },
    [contract]
  );

  const withdrawCall = useCallback(
    async ({ wrappedETHAddress, collateralAmount, lendingAddress }) => {
      if (!contract) return;

      const noLending = isEqualLowerString(lendingAddress, ZERO_ADDRESS);

      if (!contract) return;

      const tokenToGetUpdatePrice = noLending
        ? [wrappedETHAddress]
        : [wrappedETHAddress, lendingAddress];

      if (!PriceContract) return;
      const { priceIds, payableAmount, updateData } = await getDataToUpdatePrice(
        tokenToGetUpdatePrice,
        PriceContract
      );

      const callTx = await contract.withdraw(collateralAmount, priceIds, updateData, {
        value: payableAmount,
      });

      await callTx.wait();
    },
    [contract, PriceContract]
  );

  const supplyCall = useCallback(
    async ({ lendingTokenAmount }) => {
      if (!contract) return;
      const callTx = await contract.supply({ value: lendingTokenAmount });

      await callTx.wait();
    },
    [contract]
  );

  const redeemUnderlyingCall = useCallback(
    async ({ lendingTokenAmount }) => {
      if (!contract) return;

      const callTx = await contract.redeemUnderlying(lendingTokenAmount);

      await callTx.wait();
    },
    [contract]
  );

  const borrowCall = useCallback(
    async ({ collateral, lendingAmount }) => {
      if (!contract) return;
      const { address: lendingToken } = wrapNativeToken(chainId);
      const listGetTotalBorrow = [];
      lendingTokenList
        .filter((lending) => lending.address !== lendingToken)
        .forEach((lending) => {
          listGetTotalBorrow.push(getTotalBorrow(collateral, lending.address, PitContract));
        });

      const tokenAmountList = await Promise.all(listGetTotalBorrow);
      const listToUpdatePrice = [];

      tokenAmountList.forEach((token) => {
        if (+token.totalBorrow > 0) listToUpdatePrice.push(token.lendingTokenAddress);
      });

      const { priceIds, payableAmount, updateData } = await getDataToUpdatePrice(
        [...listToUpdatePrice, collateral, lendingToken],
        PriceContract
      );

      const callTx = await contract.borrow(collateral, lendingAmount, priceIds, updateData, {
        value: payableAmount,
      });

      await callTx.wait();
    },
    [PitContract, PriceContract, chainId, contract, lendingTokenList]
  );

  const repayCall = useCallback(
    async ({ collateral, lendingAmount }) => {
      if (!contract) return;
      const callTx = await contract.repay(collateral, lendingAmount, { value: lendingAmount });

      await callTx.wait();
    },
    [contract]
  );

  const leveragedBorrowWithProjectETHCall = useCallback(
    async ({
      lendingToken,
      notionalExposure,
      marginCollateralAmount,
      buyCalldata,
      leverageType,
      priceIds,
      updateData,
      updateFee,
      payableAmount,
    }) => {
      if (!contract) return;

      const callTx = await contract.leveragedBorrowWithProjectETH(
        lendingToken,
        notionalExposure,
        marginCollateralAmount,
        buyCalldata,
        leverageType,
        priceIds,
        updateData,
        updateFee,
        { value: payableAmount }
      );

      await callTx.wait();
    },
    [contract]
  );

  return {
    callback: {
      depositCall,
      withdrawCall,
      supplyCall,
      redeemUnderlyingCall,
      borrowCall,
      leveragedBorrowWithProjectETHCall,
      repayCall,
    },
    data: {
      GatewayInstance,
    },
  };
};
