import { BigNumber, Contract } from "ethers";
import { formatUnits } from "utils/number";
import { useWallet } from "hooks";
import { useMultiCallContractInstance } from "hooks/contract/multicall/useMultiCallContract";
import { get, reduce } from "lodash";
import { useQuery } from "react-query";
import { ERC20TokenABI } from "utils/ethereum/abi";
import {
  decodeResultMulticallZkSync,
  formatMulticallResults,
} from "utils/contract/decodeResultMulticall";
import { REACT_APP_ACCOUNT_HAVING_ETH } from "constants/NetworkChainId";

const handleGetResult = (result) => {
  const final = reduce(
    result,
    (res, current) => {
      const decimalHex = get(current, [0, "returnValues", 0], "0");
      const tokenAddress = get(current, [0, "reference"], "");
      res[tokenAddress] = formatUnits(BigNumber.from(decimalHex), 0);
      return res;
    },
    {}
  );
  return final;
};

export const useDecimalToken = (tokens = []) => {
  const { chainId, provider } = useWallet();
  const multicallInstance = useMultiCallContractInstance();

  return useQuery(
    [
      "decimals",
      chainId,
      tokens
        .map((s) => s.address)
        .sort()
        .join(","),
    ],
    async () => {
      const listRequestToTokenContracts = [];

      tokens.forEach((token) => {
        const TokenContract = new Contract(token.address, ERC20TokenABI, provider);

        listRequestToTokenContracts.push({
          target: token.address,
          callData: TokenContract.interface.encodeFunctionData("decimals"),
          methodName: "decimals",
          methodParameters: [],
          contract: TokenContract,
          contractName: token.address,
          reference: token.address,
          value: BigNumber.from(0),
        });
      });

      const valueOfRequest = listRequestToTokenContracts.map((r) =>
        "value" in r ? r.value : BigNumber.from(0)
      );
      const totalValue = valueOfRequest.reduce((pre, cur) => pre.add(cur), BigNumber.from(0));

      const resultMulticall = await multicallInstance.callStatic.aggregate3Value(
        listRequestToTokenContracts,
        {
          value: totalValue,
          from: REACT_APP_ACCOUNT_HAVING_ETH[chainId],
        }
      ).catch(error => {
        console.log("Request multicall fail", listRequestToTokenContracts);
        throw error
      });
      const returnData = formatMulticallResults(resultMulticall);

      const results = decodeResultMulticallZkSync(listRequestToTokenContracts, returnData);

      const decimals = handleGetResult(results);
      return decimals;
    },
    {
      enabled: !!tokens?.length,
    }
  );
};
