/* eslint-disable no-shadow */
import BigNumber from "bignumber.js";
import { logoBySymbol } from "constants/TokenListGoerliNetwork";
import { REACT_APP_SPECIAL_TOKEN_SYMBOLS } from "constants/NetworkChainId";
import { CHAIN_ID } from "app/constants";
import _ from "lodash";
import { ethers } from "ethers";

import coinData from "assets/coin-list.json";
import { numberToString } from "./number";
import COIN_INFO from "../assets/coins_info.json";
import { defaultNetwork, isEqualLowerString, isWrapNative, wrapNativeToken } from "./addressUtils";

export default function truncateText(text) {
  return `${text?.substring(0, 6)}...${text?.substring(36, 43)}`;
}

export const convertNumberHex = (value) => {
  const nbToString = numberToString(value);

  return new BigNumber(value ? nbToString : Number(value)).toString();
};

export const calculateSafetyBuffer = (healthFactor) => {
  if (healthFactor) {
    if (healthFactor === 0) return 0;
    return 1 / healthFactor > 1 ? 0 : 1 - 1 / healthFactor;
  }
  return 1;
};

export function getLogoBySymbolAndName(symbol = "", name = "", address = "") {
  let chainId = CHAIN_ID;
  if (window.ethereum?.chainId) {
    chainId = window.ethereum.chainId;
    chainId = ethers.BigNumber.from(chainId).toString();
    // if (chainId) {
    //   console.info("getLogoBySymbolAndName from chainId", chainId, symbol, name, address);
    // }
  } else {
    chainId = ethers.BigNumber.from(defaultNetwork.id).toString();
  }

  const isNative = isWrapNative(address, `0x${Number(chainId).toString(16)}`);

  // check logo by symbol
  const token = isNative
    ? wrapNativeToken(`0x${Number(chainId).toString(16)}`)
    : _.get(coinData, [chainId, address]);

  if (token) {
    return token.logo;
  }

  if (name && symbol) {
    const symbolList = COIN_INFO.filter((token) => token.symbol === symbol);

    if (symbolList.length === 1) return symbolList[0].logo;

    if (symbolList.length > 1) {
      const tokenFind = symbolList.filter((token) => {
        // eslint-disable-next-line camelcase
        const { contract_address } = token;
        return contract_address.find((contract) =>
          isEqualLowerString(contract.contract_address, address)
        );
      });

      if (tokenFind.length >= 1) return tokenFind[0].logo;

      return logoBySymbol[symbol] || logoBySymbol.PRJ1;
    }
  }

  return logoBySymbol[symbol] || logoBySymbol.PRJ1;
}

export function whiteBgForLogo(symbol = "") {
  if (symbol === "BAL") return true;

  return false;
}

export function getSpecialTokenSymbol(address = "", networkId, defaultValue = null) {
  if (!REACT_APP_SPECIAL_TOKEN_SYMBOLS) return defaultValue;

  const chainId = `0x${Number(
    Number.isNaN(networkId) ? new BigNumber(networkId).toNumber : networkId
  ).toString(16)}`;

  if (!REACT_APP_SPECIAL_TOKEN_SYMBOLS[chainId]) return defaultValue;
  return REACT_APP_SPECIAL_TOKEN_SYMBOLS[chainId][address.toLowerCase()];
}
