import { Multicall } from "ethereum-multicall";
import { useMemo } from "react";
import { REACT_APP_MULTICALL } from "constants/NetworkChainId";
import { Contract } from "ethers";
import { MultiCallZkSyncABI } from "utils/ethereum/abi";
import { defaultProvider, defaultNetwork } from "utils/addressUtils";
import useWallet from "../../useWallet";

export const useMultiCallContractInstance = () => {
  const { chainId, provider } = useWallet();
  // const multiCallAddress = useMemo(() => REACT_APP_MULTICALL[chainId], [chainId]);

  const multiCallContract = useMemo(() => {
    const multiCallAddress = REACT_APP_MULTICALL[chainId];

    if (!multiCallAddress)
      return new Multicall({
        ethersProvider: provider,
        tryAggregate: false,
        multicallCustomContractAddress: REACT_APP_MULTICALL[chainId],
      });

    const multiCallSMC = new Contract(multiCallAddress, MultiCallZkSyncABI, provider);

    return multiCallSMC;
  }, [chainId, provider]);

  return multiCallContract;
};

export const useDefaultMultiCallContractInstance = () => {
  const multiCallContract = useMemo(() => {
    const provider = defaultProvider;
    const chainId = `0x${defaultNetwork.id.toString(16)}`;
    const multiCallAddress = REACT_APP_MULTICALL[chainId];

    const multiCallSMC = new Contract(multiCallAddress, MultiCallZkSyncABI, provider);

    return multiCallSMC;
  }, []);

  return multiCallContract;
};
