import { useWallet } from "hooks";
import { useQuery } from "react-query";
import { ERC20Token } from "utils/ethereum/ERC20Service";

export const useTokenInfo = (address) => {
  const { account, chainId } = useWallet();

  return useQuery(["tokenInfo", address, "chain", chainId], async () => {
    const tokenContract = new ERC20Token(address);
    const balance = await tokenContract.getBalance(account);

    return {
      balance,
    };
  });
};
