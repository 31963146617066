import { ethers } from "ethers";
import { formatUnits } from "utils/number";
import { ERC20TokenABI } from "./abi";

export class ERC20Token {
  contract;

  provider;

  constructor(address) {
    this.provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = this.provider.getSigner();
    this.contract = new ethers.Contract(address, ERC20TokenABI, signer);
  }

  async getBalance(walletAddress) {
    try {
      const balanceOfAccount = await this.contract.balanceOf(walletAddress);
      const decimals = await this.contract.decimals();
      return formatUnits(balanceOfAccount, decimals);
    } catch (error) {
      return "0";
    }
  }
}
