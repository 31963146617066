import { Contract } from "ethers";
import { parseUnits } from "utils/number";
import { getInstantLeverageContract, getPriceProviderAggregatorZksyncContract } from "./contracts";
import { getDataToUpdatePrice } from "./getDataToUpdatePrice";

export const getLendingTokenCount = async ({
  lendingTokenAddress,
  notionalValue,
  chainId,
  signer,
}) => {
  const contractInfo = getInstantLeverageContract(chainId);

  const leverageContract = new Contract(contractInfo.address, contractInfo.abi, signer);

  const notionalValueBN = parseUnits(notionalValue.toString(), 6).toString();

  const PriceContract = () => {
    if (!signer) return null;

    const priceContractInfo = getPriceProviderAggregatorZksyncContract(chainId);

    const smc = new Contract(priceContractInfo.address, priceContractInfo.abi, signer);
    return smc;
  };

  const { priceIds, payableAmount, updateData } = await getDataToUpdatePrice(
    [lendingTokenAddress],
    PriceContract()
  );

  const lendingTokenAmount =
    await leverageContract.callStatic.calculateLendingTokenCountWithUpdatePrices(
      lendingTokenAddress,
      notionalValueBN,
      priceIds,
      updateData,
      { value: payableAmount }
    );

  return lendingTokenAmount;
};
