import axios from "axios";

const baseURL = "https://apiv5.paraswap.io";

export const createCallDataParaswap = async (
  srcToken,
  srcDecimals,
  destToken,
  destDecimals,
  amountTokenBN,
  side = "BUY",
  { chainId = 1, account }
) => {
  const { data: priceData } = await axios.get(`${baseURL}/prices/`, {
    params: {
      srcToken,
      srcDecimals,
      destDecimals,
      amount: amountTokenBN,
      destToken,
      side,
      network: +chainId,
    },
  });

  const txDataBody = {
    ...priceData,
    srcToken,
    destToken,
    slippage: "5",
    [side === "BUY" ? `destAmount` : `srcAmount`]: amountTokenBN,
    userAddress: account,
  };

  const { data: txData } = await axios.post(`${baseURL}/transactions/${+chainId}/`, txDataBody, {
    params: { ignoreChecks: true },
  });
  return txData.data;
};
