/* eslint-disable no-unused-vars */
import { Redirect, Route, Switch } from "react-router-dom";
import React from "react";

// import {
//   BorrowerNewDashboard,
//   IndexMarket,
//   MultiAsset,
//   TermsOfService,
//   Amplify,
//   MarginTrade,
// } from "pages";
import { useWallet } from "hooks";
import { isSupportZkSync, isZksyncNetwork } from "utils/addressUtils";
import { Spinner } from "components";
import AssetLanding from "pages/AssetLanding";

const BorrowerNewDashboard = React.lazy(() => import("pages/BorrowerNewDashboard"));
const MultiAsset = React.lazy(() => import("pages/MultiAssetDashboard"));
const TermsOfService = React.lazy(() => import("pages/TermsOfService"));
const Amplify = React.lazy(() => import("pages/AmplifyDashboard"));
const MarginTrade = React.lazy(() => import("pages/MarginTradeDashboard"));
const IndexMarket = React.lazy(() => import("pages/IndexMarket"));

const Routes = () => {
  const { chainId } = useWallet();

  const isIncludeLeveragePage = process.env.REACT_APP_INCLUDE_LEVERAGE === "true";
  // if (isZksyncNetwork(chainId)) {
  //   isIncludeLeveragePage = isIncludeLeveragePage && isSupportZkSync();
  // }

  return (
    <React.Suspense fallback={<Spinner position="absolute" />}>
      <Switch>
        <Route exact path="/" component={BorrowerNewDashboard} />
        <Route exact path="/lend" component={MultiAsset} />
        <Route exact path="/statistics" component={IndexMarket} />
        <Route exact path="/terms-of-service" component={TermsOfService} />
        <Route path="/borrowing/:symbol" component={AssetLanding} />
        {isIncludeLeveragePage && (
          <>
            <Route exact path="/amplify" component={Amplify} />
            <Route exact path="/margin-trade" component={MarginTrade} />
          </>
        )}
        {/* <Route exact path="/borrow-new" component={BorrowerNewDashboard} /> */}
        {/* <Route exact path="/lender-multi-asset" component={MultiAsset} /> */}
        <Redirect to="/" />
      </Switch>
    </React.Suspense>
  );
};

export default Routes;
