import { BigNumber } from "ethers";
import { getPriceFeedsUpdateData } from "pages/BorrowerNewDashboard/hooks/PITContract/handleGetExpiredPriceFeed";

export const getDataToUpdatePrice = async (tokenAddressList = [], PriceContract) => {
  if (!PriceContract) {
    return {
      priceIds: [],
      payableAmount: BigNumber.from(0),
      updateData: [],
    };
  }

  const sToken = tokenAddressList.sort();

  const { priceIds, updateFee } = await PriceContract.getExpiredPriceFeeds(
    sToken,
    BigNumber.from(15)
  );

  let updateData = [];
  if (priceIds.length > 0) {
    updateData = await getPriceFeedsUpdateData(priceIds);
  }

  return {
    priceIds,
    payableAmount: updateFee,
    updateData,
  };
};
