import { useCallback } from "react";

import { useMutation, useQueryClient } from "react-query";

import { useEventContractAction } from "context/EventContract/EventContractProvider";
import { useAtomicRepaymentContractAction } from "hooks/contract/core/useAtomicRepaymentContract";
import useWallet from "hooks/useWallet";
import { useSnackbar } from "notistack";
import { PIT_EVENT } from "utils/ethereum/pitEventConstant";
import { usePriceContract } from "hooks/contract/core/usePriceContract";
import { getDataToUpdatePrice } from "utils/ethereum/getDataToUpdatePrice";

export const useAtomicRepayment = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { onEvent } = useEventContractAction();
  const { account } = useWallet();
  const { atomicRepaymentContract } = useAtomicRepaymentContractAction();

  const { PriceContract } = usePriceContract();

  const handleError = useCallback(
    (error) => {
      onEvent(null);
      if (error.message === "userRejectedRequest") {
        enqueueSnackbar("Transaction has been canceled", {
          variant: "warning",
          autoHideDuration: 5000,
        });
      } else if (
        error.reason === "execution reverted: UniswapV2Router: INSUFFICIENT_INPUT_AMOUNT" ||
        error.reason === "execution reverted: UniswapV2: INSUFFICIENT_INPUT_AMOUNT"
      ) {
        enqueueSnackbar("Failed: Insufficient liquidity on external DEX", {
          variant: "error",
          autoHideDuration: 5000,
        });
      } else if (error.reason === "execution reverted: AtomicRepayment: invalid amount") {
        enqueueSnackbar("Failed: You don't have enough collateral amount", {
          variant: "error",
          autoHideDuration: 5000,
        });
      } else {
        enqueueSnackbar(error.reason || error.message, {
          variant: "error",
          autoHideDuration: 5000,
        });
      }
    },
    [enqueueSnackbar, onEvent]
  );

  const repayCollateral = useMutation(
    async ({ prjAddress, lendingAddress, collateralAmount, buyCalldata, isRepayFully }) => {
      if (!atomicRepaymentContract) return;

      if (!PriceContract) return;

      const { priceIds, payableAmount, updateData } = await getDataToUpdatePrice(
        [prjAddress, lendingAddress],
        PriceContract
      );

      const tx = await atomicRepaymentContract.repayAtomic(
        prjAddress,
        collateralAmount,
        buyCalldata,
        isRepayFully,
        priceIds,
        updateData,
        { value: payableAmount }
      );

      await tx.wait(1);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["available-multicall", account]);
        queryClient.invalidateQueries(["borrowed-pit-multicall", account]);
        onEvent(PIT_EVENT.RepayBorrow);

        enqueueSnackbar("The transaction has been confirmed successfully!", {
          variant: "success",
          autoHideDuration: 5000,
        });
      },
      onError: handleError,
    }
  );

  const getRepayCollateralData = useMutation(
    async ({ user, prjAddress, lendingAddress }) => {
      const { remainingDeposit, availableLendingAmount, totalOutStanding } =
        await atomicRepaymentContract.getRepayUsingCollateralData(user, prjAddress, lendingAddress);
      return { remainingDeposit, availableLendingAmount, totalOutStanding };
    },
    {
      onSuccess: () => {},
      onError: () => {},
    }
  );

  return {
    getRepayCollateralData: getRepayCollateralData.mutateAsync,
    isLoadingData: getRepayCollateralData.isLoading,
    repayCollateral: repayCollateral.mutateAsync,
    isWaitingTx: repayCollateral.isLoading,
  };
};
