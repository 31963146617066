import { USD_DECIMAL } from "constants/contract";
import { constants } from "ethers";
import { formatUnits } from "utils/number";
import { get } from "lodash";
import methodOfPITContract from "./methodOfPitContract";

const { limitBorrowInLendingTokenMethod } = methodOfPITContract;

export function getLimitBorrowingOfLendingTokenFromMultiData(methodDataOnPitContract) {
  return get(methodDataOnPitContract, [limitBorrowInLendingTokenMethod])?.reduce(
    (res, resultCall) => ({
      ...res,
      [get(resultCall, ["reference"], constants.AddressZero)]: formatUnits(
        get(resultCall, ["returnValues", 0], "0"),
        USD_DECIMAL
      ),
    }),
    {}
  );
}
