import { CircularProgress } from "@material-ui/core";
import { useLeverageContext } from "context/InstantsLeverage/LeverageContext/useLeverageContext";
import { BigNumber, Contract } from "ethers";
import useWallet from "hooks/useWallet";
import { get } from "lodash";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  useMutation as useRTKMutation,
  useQuery as useRTKQuery,
  useQueryClient as useRTKQueryClient,
} from "react-query";

import { getInstantLeverageContract } from "utils/ethereum/contracts";
import { createSellCallData } from "utils/ethereum/createSellData";
import { useAmplifyContext } from "context/InstantsLeverage/AmplifyContext/useAmplifyContext";
import { useMarginTradeContext } from "context/InstantsLeverage/MarginTradeContext/useMarginTradeContext";
import { parseUnits } from "utils/number";
import { usePriceContract } from "hooks/contract/core/usePriceContract";
import { getDataToUpdatePrice } from "utils/ethereum/getDataToUpdatePrice";
import {
  decodeResultMulticallZkSync,
  formatMulticallResults,
} from "utils/contract/decodeResultMulticall";
import { REACT_APP_ACCOUNT_HAVING_ETH } from "constants/NetworkChainId";
import { isWrapNative } from "utils/addressUtils";
import { useMultiCallContractInstance } from "./multicall/useMultiCallContract";
import { usePITWrappedTokenGatewayContract } from "./usePITWrappedTokenGatewayContract";

const usePITLeverageContract = (chainId, signer) =>
  useMemo(() => {
    const contractInfo = getInstantLeverageContract(chainId);
    return new Contract(contractInfo.address, contractInfo.abi, signer);
  }, [chainId, signer]);

const calculateLendingTokenCount = async (
  lendingToken,
  notional,
  priceContract,
  leverageContract
) => {
  const { priceIds, payableAmount, updateData } = await getDataToUpdatePrice(
    [lendingToken],
    priceContract
  );

  const res = await leverageContract.callStatic.calculateLendingTokenCountWithUpdatePrices(
    lendingToken,
    notional,
    priceIds,
    updateData,
    { value: payableAmount }
  );

  return res;
};

export const useLeverageContract = () => {
  const queryRTKClient = useRTKQueryClient();
  const { account, chainId, signer } = useWallet();
  const { PriceContract } = usePriceContract();

  const leverageContract = usePITLeverageContract(chainId, signer);
  const {
    callback: { leveragedBorrowWithProjectETHCall },
  } = usePITWrappedTokenGatewayContract();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const {
    refetchLaveragedBorrowList,
    shortAssetSelected,
    notionalExp: NEXP,
  } = useLeverageContext();

  const { resetStates: resetAmplifyContextStates } = useAmplifyContext();
  const { resetStates: resetMarginTradeContextStates } = useMarginTradeContext();

  const [shortAmountt, setShortAmount] = useState();

  const getShortAmount = useCallback(async () => {
    if (!shortAssetSelected || !NEXP || NEXP === "NaN" || !leverageContract) return;
    const notionalExpBN = parseUnits(NEXP, 6).toString();
    const shortAmount = await calculateLendingTokenCount(
      shortAssetSelected.address,
      notionalExpBN,
      PriceContract,
      leverageContract
    );

    setShortAmount(shortAmount);
  }, [shortAssetSelected, NEXP, leverageContract, PriceContract]);

  useEffect(() => {
    getShortAmount();
  }, [getShortAmount]);

  const { mutateAsync: openLeveragePosition, isLoading } = useRTKMutation(
    async (variable) => {
      const { longAsset, shortAsset, notionalExp, marginLong, leverageType } = variable;

      const { address: shortAddress } = shortAsset;
      const { address: longAddress } = longAsset;

      const shortAmount = await calculateLendingTokenCount(
        shortAddress,
        notionalExp,
        PriceContract,
        leverageContract
      );

      const sellCalldata = await createSellCallData({
        tokenCollateral: longAsset,
        lendingToken: shortAsset,
        amountIn: shortAmount.toString(),
        amountOutMin: "0",
        chainId,
        account: leverageContract.address,
        signer,
      });

      const { priceIds, payableAmount, updateData } = await getDataToUpdatePrice(
        [longAddress, shortAddress],
        PriceContract
      );
      if (isWrapNative(longAddress, chainId)) {
        const addingAmount = await leverageContract.calculateAddingAmount(
          account,
          longAddress,
          marginLong
        );
        await leveragedBorrowWithProjectETHCall({
          lendingToken: shortAddress,
          notionalExposure: notionalExp,
          marginCollateralAmount: marginLong,
          buyCalldata: sellCalldata,
          leverageType,
          priceIds,
          updateData,
          updateFee: payableAmount,
          payableAmount: BigNumber.from(addingAmount).add(payableAmount),
        });
      } else {
        const tx = await leverageContract.leveragedBorrow(
          longAddress,
          shortAddress,
          notionalExp,
          marginLong,
          sellCalldata,
          leverageType,
          priceIds,
          updateData,
          { value: payableAmount }
        );
        await tx.wait(1);
      }
    },
    {
      onSettled: async (_data, err) => {
        closeSnackbar();
        if (err) {
          const message =
            JSON.stringify(err)
              .split('"')
              .find((mes) => mes.includes("reverted")) ||
            get(err, "reason") ||
            get(err, "message");
          enqueueSnackbar(`Error Tx - ${message}!`, { variant: "error", autoHideDuration: 5000 });
        } else {
          await queryRTKClient.invalidateQueries(["available-multicall", account]);
          await queryRTKClient.invalidateQueries(["borrowed-pit-multicall", account]);
          enqueueSnackbar("Success Tx:Leverage Position Opened!", {
            variant: "success",
            autoHideDuration: 5000,
          });

          if (resetAmplifyContextStates) {
            resetAmplifyContextStates();
          }

          if (resetMarginTradeContextStates) {
            resetMarginTradeContextStates();
          }

          await refetchLaveragedBorrowList({
            account,
          });
        }
      },
      onMutate: () => {
        enqueueSnackbar(
          <>
            <CircularProgress size={16} color="inherit" style={{ marginRight: "8px" }} /> Waiting
            Transaction: Open Leverage Position!
          </>,
          {
            persist: true,
            autoHideDuration: 5000,
          }
        );
      },
    }
  );

  return {
    openLeveragePosition: isLoading ? () => {} : openLeveragePosition,
    shortAmount: shortAmountt,
  };
};

export const useCheckLeveragePositions = (collaterals = []) => {
  const multicallInstance = useMultiCallContractInstance();
  const { account, chainId, provider } = useWallet();

  const {
    refetch: checkLeveragePosition,
    data: leverageData,
    isFetched,
  } = useRTKQuery(
    [collaterals, chainId, account, "is-leverage-position"],
    async ({ queryKey }) => {
      const [collateralList, networkID] = queryKey;
      const contractPITLeverage = getInstantLeverageContract(networkID);

      const PITLeverageContract = new Contract(
        contractPITLeverage.address,
        contractPITLeverage.abi,
        provider
      );

      const requests = [];
      collateralList.forEach((collateralAddress) => {
        requests.push({
          target: contractPITLeverage.address,
          callData: PITLeverageContract.interface.encodeFunctionData("isLeveragePosition", [
            account,
            collateralAddress,
          ]),
          reference: "isLeveragePosition",
          methodName: "isLeveragePosition",
          methodParameters: [account, collateralAddress],
          contract: PITLeverageContract,
          contractName: contractPITLeverage.address,
          value: BigNumber.from(0),
        });
      });

      const valueOfRequest = requests.map((r) => ("value" in r ? r.value : BigNumber.from(0)));
      const totalValue = valueOfRequest.reduce((pre, cur) => pre.add(cur), BigNumber.from(0));

      const resultMulticall = await multicallInstance.callStatic.aggregate3Value(requests, {
        value: totalValue,
        from: REACT_APP_ACCOUNT_HAVING_ETH[chainId],
      }).catch(error => {
        console.log("Request multicall fail", requests);
        throw error
      });
      const returnData = formatMulticallResults(resultMulticall);

      const results = decodeResultMulticallZkSync(requests, returnData);
      const res = results[contractPITLeverage.address].map((call) => call.returnValues[0]);

      const dataRes = new Map();

      collateralList.forEach((collateralAddress, index) => {
        dataRes.set(collateralAddress, res[index]);
      });

      return dataRes;
    },
    {
      enabled: false,
      onError: (err) => new Map().set("error", err),
    }
  );

  return { checkLeveragePosition, leverageData, isFetched };
};

export const useGetLeverageTypes = (collaterals = []) => {
  const multicallInstance = useMultiCallContractInstance();
  const { account, chainId, provider } = useWallet();

  const {
    refetch: getLeverageTypes,
    data: leverageData,
    isFetched,
  } = useRTKQuery(
    [collaterals, chainId, account, "leverage-type"],
    async ({ queryKey }) => {
      const [collateralList, networkID] = queryKey;
      const contractPITLeverage = getInstantLeverageContract(networkID);

      const PITLeverageContract = new Contract(
        contractPITLeverage.address,
        contractPITLeverage.abi,
        provider
      );

      const requests = [];
      collateralList.forEach((collateralAddress) => {
        requests.push({
          target: contractPITLeverage.address,
          callData: PITLeverageContract.interface.encodeFunctionData("getLeverageType", [
            account,
            collateralAddress,
          ]),
          reference: "getLeverageType",
          methodName: "getLeverageType",
          methodParameters: [account, collateralAddress],
          contract: PITLeverageContract,
          contractName: contractPITLeverage.address,
          value: BigNumber.from(0),
        });
      });

      const valueOfRequest = requests.map((r) => ("value" in r ? r.value : BigNumber.from(0)));
      const totalValue = valueOfRequest.reduce((pre, cur) => pre.add(cur), BigNumber.from(0));

      const resultMulticall = await multicallInstance.callStatic.aggregate3Value(requests, {
        value: totalValue,
        from: REACT_APP_ACCOUNT_HAVING_ETH[chainId],
      }).catch(error => {
        console.log("Request multicall fail", requests);
        throw error
      });
      const returnData = formatMulticallResults(resultMulticall);

      const results = decodeResultMulticallZkSync(requests, returnData);
      const res = results[contractPITLeverage.address].map((call) => call.returnValues[0]);

      const dataRes = new Map();

      collateralList.forEach((collateralAddress, index) => {
        dataRes.set(collateralAddress, res[index]);
      });

      return dataRes;
    },
    {
      enabled: false,
      onError: (err) => new Map().set("error", err),
    }
  );

  return { getLeverageTypes, leverageData, isFetched };
};
