import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import numeral from "numeral";
import { Typography, Grid, Avatar, Box, CircularProgress } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    color: "#ffffff",
    paddingBottom: 10,
    paddingTop: 10,
    paddingLeft: "15%",
    paddingRight: "15%",
    [theme.breakpoints.down("sm")]: {
      padding: 0,
    },
  },
  content: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "center",
      gap: 10,
    },
  },
  avatar: {
    display: "flex",
    alignItems: "center",
    gap: 20,
    "& p": {
      fontSize: "1.5rem",
    },
    "& img": {
      height: 40,
    },
  },
  summary: {
    display: "flex",
    gap: 30,
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      justifyContent: "space-around",
    },
  },
  summaryTitle: {
    fontSize: "1rem",
    color: "#a5a8b6",
  },
  summaryValue: {
    fontSize: "1.5rem",
    fontWeight: 600,
  },
}));

const CoinSummary = ({ logo, symbol, name, lendingAPY, borrowingAPY, price, isLoading }) => {
  const classes = useStyles();
  const formatPercentValue = (value) => numeral(value).format("0,0.[00]");
  return (
    <Grid className={classes.container}>
      <Grid className={classes.content}>
        <Grid item className={classes.avatar}>
          <Avatar src={logo} alt="Coin Logo" />
          <Box>
            <Typography>{symbol}</Typography>
            <Typography>{name}</Typography>
          </Box>
        </Grid>
        <Grid className={classes.summary}>
          <Grid>
            <Typography className={classes.summaryTitle}>Lending APY</Typography>
            {isLoading ? (
              <CircularProgress color="secondary" />
            ) : (
              <Typography className={classes.summaryValue}>
                {formatPercentValue(lendingAPY)}%
              </Typography>
            )}
          </Grid>
          <Grid>
            <Typography className={classes.summaryTitle}>Borrowing APY</Typography>
            {isLoading ? (
              <CircularProgress color="secondary" />
            ) : (
              <Typography className={classes.summaryValue}>
                {formatPercentValue(borrowingAPY)}%
              </Typography>
            )}
          </Grid>
          <Grid>
            <Typography className={classes.summaryTitle}>Price</Typography>
            {isLoading ? (
              <CircularProgress color="secondary" />
            ) : (
              <Typography className={classes.summaryValue}>${price}</Typography>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

CoinSummary.propTypes = {
  logo: PropTypes.string.isRequired,
  symbol: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  lendingAPY: PropTypes.number.isRequired,
  borrowingAPY: PropTypes.number.isRequired,
  price: PropTypes.number.isRequired,
  isLoading: PropTypes.bool,
};

CoinSummary.defaultProps = {
  isLoading: true,
};

export default CoinSummary;
