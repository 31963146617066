import { constants, Contract } from "ethers";
import React, { useCallback } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { ERC20TokenABI } from "utils/ethereum/abi";
import { useSnackbar } from "notistack";
import useWallet from "hooks/useWallet";

export const useERC20TokenApproval = (tokenAddress, spenders = []) => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { signer, account } = useWallet();
  const ERC20 = React.useMemo(
    () => (tokenAddress ? new Contract(tokenAddress, ERC20TokenABI, signer) : null),
    [signer, tokenAddress]
  );

  const handleError = useCallback(
    (error) => {
      if (error.message === "userRejectedRequest" || error.code === "ACTION_REJECTED") {
        enqueueSnackbar("Transaction has been canceled", {
          variant: "error",
          autoHideDuration: 5000,
        });
      } else {
        enqueueSnackbar(`Transaction rejected: ${error?.reason}`, {
          variant: "error",
          autoHideDuration: 5000,
        });
      }
    },
    [enqueueSnackbar]
  );

  const query = useQuery(
    [`erc20-token${tokenAddress}`, spenders[0]],
    async () => {
      if (spenders.length < 2) {
        const spender = spenders[0];

        const res = await ERC20.functions.allowance(account, spender);
        return res[0];
      }
      return false;
    },
    {
      enabled: Boolean(account) && Boolean(tokenAddress) && spenders.length > 0,
    }
  );

  const mutateApproval = useMutation(
    async (params) => {
      if (spenders.length < 2) {
        const spender = spenders[0];
        const tx = await ERC20.approve(spender, params?.value || constants.MaxUint256);
        await tx.wait(1);
      }
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries([`erc20-token${tokenAddress}`, spenders[0]]);
        await queryClient.invalidateQueries(["available-multicall", account]);
        await queryClient.invalidateQueries(["borrowed-pit-multicall", account]);
        enqueueSnackbar("Transaction approved!", {
          variant: "success",
          autoHideDuration: 5000,
        });
      },
      onError: handleError,
    }
  );

  return { query, approve: mutateApproval.mutateAsync, isLoading: mutateApproval.isLoading };
};
