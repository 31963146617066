import { BigNumber, Contract, ethers } from "ethers";
import { formatUnits } from "utils/number";
import { useWallet } from "hooks";
import { useFetchGraphData } from "hooks/query/graphQL/useFetchGraphData";
import {
  useMultiCallContractInstance,
  useDefaultMultiCallContractInstance,
} from "hooks/contract/multicall/useMultiCallContract";
import { get, reduce } from "lodash";
import { useQuery } from "react-query";
import { ERC20TokenABI, FTokenABI } from "utils/ethereum/abi";
import {
  decodeResultMulticallZkSync,
  formatMulticallResults,
} from "utils/contract/decodeResultMulticall";
import { REACT_APP_ACCOUNT_HAVING_ETH } from "constants/NetworkChainId";

export const getCashDataFromResultMulticall = (result) => {
  let cashFToken = {};

  const final = reduce(
    result,
    (res, current, key) => {
      const raw = get(current, ["0", "returnValues"], "0");
      const methodName = get(current, ["0", "methodName"], "0");

      if (methodName === "allowance")
        return {
          ...res,
          [key]: {
            allowance: ethers.BigNumber.from(raw[0]),
          },
        };

      const decimal = get(current, ["1", "returnValues", 0], "0");
      const lendingToken = get(current, ["1", "reference"], "0");

      const cashValue = BigNumber.from(raw[0]);

      cashFToken = {
        ...cashFToken,
        [lendingToken]: {
          cash: formatUnits(cashValue, decimal),
        },
      };
      return res;
    },
    {}
  );

  return { final, cashFToken };
};

export const useAllowanceToken = (fTokens = []) => {
  const { account, chainId, provider } = useWallet();
  const { availableBorrowTokens = [] } = useFetchGraphData();
  const multicallInstance = useMultiCallContractInstance();
  const defaultMulticallInstance = useDefaultMultiCallContractInstance();

  return useQuery(
    ["allowance", account, chainId],
    async () => {
      const listRequestToTokenContracts = [];
      const multicall = account ? multicallInstance : defaultMulticallInstance;

      if (account) {
        availableBorrowTokens.forEach((token) => {
          const TokenContract = new Contract(token.address, ERC20TokenABI, provider);

          listRequestToTokenContracts.push({
            target: token.address,
            callData: TokenContract.interface.encodeFunctionData("allowance", [
              account,
              fTokens.find((o) => o.token === token.address).ftoken,
            ]),
            methodName: "allowance",
            methodParameters: [account, fTokens.find((o) => o.token === token.address).ftoken],
            reference: "isAllowanceForFToken",
            contract: TokenContract,
            contractName: token.address,
            value: ethers.BigNumber.from(0),
          });
        });
      }

      fTokens.forEach((token) => {
        const FTokenContract = new Contract(token.ftoken, FTokenABI, provider);

        listRequestToTokenContracts.push(
          {
            target: token.ftoken,
            callData: FTokenContract.interface.encodeFunctionData("getCash", []),
            methodParameters: [],
            reference: "CashForLendingToken",
            methodName: "getCash",
            contractName: token.ftoken,
            contract: FTokenContract,
            value: ethers.BigNumber.from(0),
          },
          {
            target: token.ftoken,
            callData: FTokenContract.interface.encodeFunctionData("decimals", []),
            methodParameters: [],
            reference: token.token,
            methodName: "decimals",
            contractName: token.ftoken,
            contract: FTokenContract,
            value: ethers.BigNumber.from(0),
          }
        );
      });

      const valueOfRequest = listRequestToTokenContracts.map((r) =>
        "value" in r ? r.value : BigNumber.from(0)
      );
      const totalValue = valueOfRequest.reduce((pre, cur) => pre.add(cur), BigNumber.from(0));

      const resultMulticall = await multicall.callStatic.aggregate3Value(
        listRequestToTokenContracts,
        {
          value: totalValue,
          from: REACT_APP_ACCOUNT_HAVING_ETH[chainId],
        }
      ).catch(error => {
        console.log("Request multicall fail", listRequestToTokenContracts);
        throw error
      });
      const returnData = formatMulticallResults(resultMulticall);

      const results = decodeResultMulticallZkSync(listRequestToTokenContracts, returnData);
      const { final: allowanceForFToken, cashFToken } = getCashDataFromResultMulticall(results);

      return {
        allowanceForFToken,
        cashFToken,
      };
    },
    {
      enabled: fTokens.length > 0 && availableBorrowTokens.length > 0,
    }
  );
};
