import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import { NavLink as Link } from "react-router-dom";
import { Typography, Grid, Box, Button } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    color: "#ffffff",
    paddingBottom: 10,
    paddingTop: 10,
    paddingLeft: "15%",
    paddingRight: "10%",
    display: "flex",
    gap: 10,
    flexWrap: "wrap",
    marginBottom: 30,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      padding: 0,
    },
  },
  logo: {
    width: "45%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    "& img": {
      width: 200,
      marginBottom: 30,
    },
    "& h5": {
      color: "unset",
      fontWeight: 700,
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  descriptionContainer: {
    width: "45%",
    textAlign: "left",
    "& p": {
      fontSize: "1.25rem",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  title: {
    fontSize: "2rem",
    marginBottom: 15,
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  description: {},
  action: {
    paddingBottom: 30,
    display: "flex",
    gap: 50,
    justifyContent: "center",
    width: "100%",
    "& button": {
      fontSize: "1.25rem",
      width: 150,
      height: 50,
      boxShadow: "0px 0px 0px 10px #141414",
      "&:hover": {
        color: theme.palette.secondary.main,
        background: theme.palette.primary.light,
        boxShadow: "0px 0px 0px 10px #141414",
      },
    },
  },
}));

const CoinDescription = ({ name, symbol, description, logo }) => {
  const classes = useStyles();

  return (
    <Box>
      <Grid className={classes.container}>
        <Grid item className={classes.logo}>
          <img src={logo} alt={symbol} />
          <Typography variant="h5">LENDING MARKET</Typography>
        </Grid>
        <Grid className={classes.descriptionContainer}>
          <Typography variant="h1" className={classes.title}>
            Lend out, borrow against and margin trade your {name}
          </Typography>
          <Typography className={classes.description}>{description}</Typography>
        </Grid>
      </Grid>
      <Grid className={classes.action}>
        <Box className={classes.lendButton}>
          <Link to="/lend">
            <Button>Lend</Button>
          </Link>
        </Box>
        <Box className={classes.borrowButton}>
          <Link to="/">
            <Button>Borrow</Button>
          </Link>
        </Box>
      </Grid>
    </Box>
  );
};

CoinDescription.propTypes = {
  name: PropTypes.string.isRequired,
  symbol: PropTypes.string,
  description: PropTypes.string,
  logo: PropTypes.string,
};

CoinDescription.defaultProps = {
  symbol: "",
  description: "",
  logo: "",
};

export default CoinDescription;
