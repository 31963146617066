import { BigNumber } from "ethers";

export const getTotalBorrow = async (projectTokenAddress, lendingTokenAddress, PITContract) => {
  if (!PITContract) return BigNumber.from(0);

  const totalBorrow = await PITContract.totalBorrow(projectTokenAddress, lendingTokenAddress);

  return {
    lendingTokenAddress,
    totalBorrow,
  };
};
