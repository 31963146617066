import PropTypes from "prop-types";
import numeral from "numeral";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Grid, Box, CircularProgress } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    textAlign: "left",
    marginLeft: "15%",
    marginRight: "15%",
    color: "#ffffff",
    [theme.breakpoints.down("sm")]: {
      margin: 0,
    },
  },
  title: {
    fontSize: "26px",
    marginBottom: 20,
    marginTop: 20,
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  stats: {
    background: "#454545",
    border: "3px solid #5c5c5c",
    borderRadius: "2px",
    paddingBottom: 10,
    paddingTop: 10,
    paddingLeft: 20,
    paddingRight: 20,
    display: "flex",
    justifyContent: "space-between",
    gap: 40,
    flexWrap: "wrap",
    marginBottom: 30,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      justifyContent: "center",
    },
  },
  statsLoading: {
    justifyContent: "center !important",
  },
  statsItem: {
    [theme.breakpoints.down("sm")]: {
      "& p": {
        textAlign: "center",
      },
    },
  },
  statsItemTitle: {
    fontSize: "1rem",
    color: "#a5a8b6",
  },
  statsItemUsdValue: {
    fontSize: "1.5rem",
    fontWeight: 600,
  },
  statsItemTokenValue: {
    fontSize: "0.875rem",
    color: "#a5a8b6",
  },
}));

const CoinStats = ({
  name,
  price,
  symbol,
  totalSuppliedUds,
  totalBorrowedUds,
  lvr,
  debtLimitUds,
  utilization,
  isLoading,
}) => {
  const classes = useStyles();

  const formatUsdValue = (value) => {
    const formatted = numeral(value).format("0,0.[]");
    if (formatted.toLowerCase() === "nan") {
      return 0;
    }
    return formatted;
  };
  const formatTokenValue = (value) => {
    const format = Number(value) > 1000 ? "0,0.[]" : "0,0.[0000]";
    const formatted = numeral(value).format(format);
    if (formatted.toLowerCase() === "nan") {
      return 0;
    }
    return formatted;
  };
  const formatPercentValue = (value) => {
    const formatted = numeral(value * 100).format("0.[00]");
    if (formatted.toLowerCase() === "nan") {
      return 0;
    }
    return formatted;
  };

  return (
    <Box className={classes.container}>
      <Typography className={`${classes.title} loading`} variant="h2">
        {name} lending market stats
      </Typography>
      {isLoading ? (
        <Grid className={[classes.stats, classes.statsLoading]}>
          <CircularProgress color="secondary" />
        </Grid>
      ) : (
        <Grid className={classes.stats}>
          <Box className={classes.statsItem}>
            <Typography className={classes.statsItemTitle}>Total Supplied</Typography>
            <Typography className={classes.statsItemUsdValue}>
              ${formatUsdValue(totalSuppliedUds)}
            </Typography>
            <Typography className={classes.statsItemTokenValue}>
              {formatTokenValue(totalSuppliedUds / price)} {symbol}
            </Typography>
          </Box>
          <Box className={classes.statsItem}>
            <Typography className={classes.statsItemTitle}>Total Borrowed</Typography>
            <Typography className={classes.statsItemUsdValue}>
              ${formatUsdValue(totalBorrowedUds)}
            </Typography>
            <Typography className={classes.statsItemTokenValue}>
              {formatTokenValue(totalBorrowedUds / price)} {symbol}
            </Typography>
          </Box>
          <Box className={classes.statsItem}>
            <Typography className={classes.statsItemTitle}>LVR</Typography>
            <Typography className={classes.statsItemUsdValue}>
              {formatPercentValue(lvr)}%
            </Typography>
          </Box>
          <Box className={classes.statsItem}>
            <Typography className={classes.statsItemTitle}>Debt Limit</Typography>
            <Typography className={classes.statsItemUsdValue}>
              ${formatUsdValue(debtLimitUds)}
            </Typography>
            <Typography className={classes.statsItemTokenValue}>
              {formatTokenValue(debtLimitUds / price)} {symbol}
            </Typography>
          </Box>
          <Box className={classes.statsItem}>
            <Typography className={classes.statsItemTitle}>Utilization</Typography>
            <Typography className={classes.statsItemUsdValue}>
              {formatPercentValue(utilization)}%
            </Typography>
          </Box>
        </Grid>
      )}
    </Box>
  );
};

CoinStats.propTypes = {
  name: PropTypes.string.isRequired,
  symbol: PropTypes.string.isRequired,
  totalSuppliedUds: PropTypes.number.isRequired,
  totalBorrowedUds: PropTypes.number.isRequired,
  lvr: PropTypes.number.isRequired,
  debtLimitUds: PropTypes.number.isRequired,
  utilization: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,
  price: PropTypes.number.isRequired,
};

CoinStats.defaultProps = {};

export default CoinStats;
