/* eslint-disable prefer-destructuring */
import { useMemo } from "react";
import { ERC20TokenABI, MultiCallZkSyncABI } from "utils/ethereum/abi";
import { formatUnits } from "utils/number";
import useWallet from "hooks/useWallet";
import {
  defaultProvider,
  defaultNetwork,
  isWrapNative,
  wrapNativeToken,
  ZERO_ADDRESS,
} from "utils/addressUtils";
import { REACT_APP_MULTICALL } from "constants/NetworkChainId";
import { Contract, utils } from "ethers";
import { useQuery } from "react-query";
import { getPrice } from "pages/BorrowerNewDashboard/hooks/useTokenSupported";
import { getPriceProviderAggregatorContract, getPrimaryIndexToken } from "utils/ethereum/contracts";
import { useApolloClient } from "@apollo/client";
import { GET_MARKET_STATE, handleGetLastValue } from "hooks/query/graphQL/useMarketChart";

const decodeMulticallResult = (results, requests) => {
  const decoded = requests.map((data, index) => {
    const { methodName, iface } = data;
    const result = results[index]?.returnData;

    let decodeValue;

    try {
      decodeValue = iface.decodeFunctionResult(methodName, result);
    } catch (e) {
      if (methodName === "name" || methodName === "symbol") {
        decodeValue = utils.parseBytes32String(result);
      } else {
        throw e;
      }
    }

    return {
      methodName,
      value: decodeValue,
    };
  });

  const returnedData = {};
  decoded.forEach((data) => {
    returnedData[data.methodName] = data.value;
  });

  return returnedData;
};

export const useTokenInfo = (address) => {
  const { connected, chainId, provider } = useWallet();
  const selectedChainId = useMemo(
    () => (!connected ? `0x${defaultNetwork.id.toString(16)}` : chainId),
    [chainId, connected]
  );
  const PitInstance = useMemo(() => getPrimaryIndexToken(selectedChainId), [selectedChainId]);
  const PriceInstance = useMemo(
    () => getPriceProviderAggregatorContract(selectedChainId),
    [selectedChainId]
  );

  const multiCallContract = useMemo(() => {
    const selectedProvider = !connected ? defaultProvider : provider;
    const multiCallAddress = REACT_APP_MULTICALL[selectedChainId];

    const multiCallSMC = new Contract(multiCallAddress, MultiCallZkSyncABI, selectedProvider);

    return multiCallSMC;
  }, [selectedChainId, connected, provider]);

  const results = useQuery(
    ["landing-token-info", address],
    async () => {
      const erc20Iface = new utils.Interface(ERC20TokenABI);
      const pitIface = new utils.Interface(PitInstance.abi);
      const priceIface = new utils.Interface(PriceInstance.abi);
      const listRequest = [];
      listRequest.push(
        {
          target: address,
          iface: erc20Iface,
          callData: erc20Iface.encodeFunctionData("name", []),
          reference: address,
          methodName: "name",
          methodParameters: [],
          value: 0,
          contractName: "ERC20",
        },
        {
          target: address,
          iface: erc20Iface,
          callData: erc20Iface.encodeFunctionData("symbol", []),
          reference: address,
          methodName: "symbol",
          methodParameters: [],
          value: 0,
          contractName: "ERC20",
        },
        {
          target: address,
          iface: erc20Iface,
          callData: erc20Iface.encodeFunctionData("decimals", []),
          reference: address,
          methodName: "decimals",
          methodParameters: [],
          value: 0,
          contractName: "ERC20",
        },
        {
          target: PitInstance.address,
          iface: pitIface,
          callData: pitIface.encodeFunctionData("lendingTokenInfo", [address]),
          reference: address,
          methodName: "lendingTokenInfo",
          methodParameters: [address],
          value: 0,
          contractName: "PITContract",
        },
        {
          target: PitInstance.address,
          iface: pitIface,
          callData: pitIface.encodeFunctionData("borrowLimitPerLendingToken", [address]),
          reference: address,
          methodName: "borrowLimitPerLendingToken",
          methodParameters: [address],
          value: 0,
          contractName: "PITContract",
        },
        {
          target: PriceInstance.address,
          iface: priceIface,
          callData: priceIface.encodeFunctionData("tokenPriceProvider", [address]),
          reference: address,
          methodName: "tokenPriceProvider",
          methodParameters: [address],
          value: 0,
          contractName: "PriceContract",
        }
      );
      const multicallRes = await multiCallContract.callStatic.aggregate3Value(listRequest, {
        value: 0,
      }).catch(error => {
        console.log("Request multicall fail", listRequest);
        throw error
      });
      const values = decodeMulticallResult(multicallRes, listRequest);
      return values;
    },
    { enabled: !!address }
  );

  const tokenInfo = useMemo(() => {
    const isNative = isWrapNative(address, selectedChainId);
    const nativeCoin = wrapNativeToken(selectedChainId);
    const token = {
      name: "",
      symbol: "",
      decimal: 18,
      lvr: 0,
      debtLimitUds: 0,
      bLendingToken: "",
      priceProvider: "",
    };
    if (results.isFetched && results.isSuccess) {
      const {
        name,
        symbol,
        decimals,
        lendingTokenInfo,
        borrowLimitPerLendingToken,
        tokenPriceProvider,
      } = results.data;
      token.name = isNative ? nativeCoin.name : name[0];
      token.symbol = isNative ? nativeCoin.symbol : symbol[0];
      token.decimal = decimals[0];
      token.debtLimitUds = formatUnits(borrowLimitPerLendingToken[0], 6);
      token.lvr = lendingTokenInfo[3].numerator / lendingTokenInfo[3].denominator;
      token.bLendingToken = lendingTokenInfo[2];
      token.priceProvider = tokenPriceProvider[0];
    }
    return token;
  }, [address, results, selectedChainId]);

  return {
    loading: false,
    ...tokenInfo,
  };
};

export const useBlendingTokenInfo = (address) => {
  const client = useApolloClient();

  const marketData = useQuery(
    ["get-all-market-data", address],
    async () => {
      let hasNextPage = true;
      let cursor = 0;
      const allResults = {};

      while (hasNextPage) {
        // eslint-disable-next-line no-await-in-loop
        const { data } = await client.query({
          query: GET_MARKET_STATE,
          variables: {
            lendingToken: address,
            skip: cursor,
            startAt: 0,
            limit: 1000,
          },
        });

        hasNextPage = false;
        // eslint-disable-next-line no-loop-func
        Object.keys(data).forEach((key) => {
          if (!allResults[key]) {
            allResults[key] = [];
          }
          allResults[key] = allResults[key].concat(data[key]);
          if (data[key].length === 1000) {
            hasNextPage = true;
          }
        });
        cursor += 1000;
      }
      return allResults;
    },
    { enabled: !!address }
  );

  const [totalSupplyUsd, totalBorrowsUsd, borrowApy, lenderApy] = useMemo(() => {
    if (!!address && marketData.data) {
      const {
        borrowingAPYHistories,
        lenderAPYHistories,
        outstandingHistories,
        lenderAggregateCapitalDepositedHistories,
      } = handleGetLastValue(marketData.data);

      return [
        lenderAggregateCapitalDepositedHistories?.amount,
        outstandingHistories?.amount,
        borrowingAPYHistories?.amount,
        lenderAPYHistories?.amount,
      ];
    }
    return [0, 0, 0, 0];
  }, [address, marketData.data]);

  return {
    loading: marketData.isLoading,
    totalSupplyUsd,
    totalBorrowsUsd,
    borrowApy,
    lenderApy,
  };
};

export const usePriceToken = (address, priceProvider) => {
  const { connected, chainId, provider } = useWallet();
  const selectedChainId = useMemo(
    () => (!connected ? `0x${defaultNetwork.id.toString(16)}` : chainId),
    [chainId, connected]
  );
  const PriceInstance = useMemo(
    () => getPriceProviderAggregatorContract(selectedChainId),
    [selectedChainId]
  );
  const multiCallContract = useMemo(() => {
    const selectedProvider = !connected ? defaultProvider : provider;
    const multiCallAddress = REACT_APP_MULTICALL[selectedChainId];

    const multiCallSMC = new Contract(multiCallAddress, MultiCallZkSyncABI, selectedProvider);

    return multiCallSMC;
  }, [selectedChainId, connected, provider]);
  const getPriceTokens = useQuery(
    ["get-price-token", address],
    async () => {
      const prices = await getPrice(
        [{ address }],
        { [address]: priceProvider },
        PriceInstance,
        multiCallContract,
        connected ? provider : null
      );
      return prices[address];
    },
    {
      enabled:
        !!address && !!priceProvider && priceProvider !== ZERO_ADDRESS && address !== ZERO_ADDRESS,
    }
  );

  return {
    price: getPriceTokens.data,
    error: getPriceTokens.isError,
    isLoading: getPriceTokens.isLoading,
  };
};
