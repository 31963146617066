import BigNumber from "bignumber.js";
import { get, groupBy } from "lodash";
import { formatUnits } from "utils/number";

import { USD_DECIMAL } from "constants/contract";
import { useBorrowContext } from "context/contracts/BorrowContext";
import { useMemo } from "react";

const DEFAULT_CONTEXT = {
  collateralTokenObj: {},
  lendingTokensObj: {},
  collateralList: [],
  lendingList: [],
};

const EMPTY_OBJECT = {};
const EMPTY_ARRAY = [];

const useAllBorrowData = () => {
  const context = useBorrowContext();

  const isFetched = context?.isFetched;
  const collateralTokens = get(context, "availableDepositTokens", EMPTY_ARRAY);
  const decimalToken = get(context, "decimalOfContractToken", EMPTY_OBJECT);
  const lvrCollateralTokens = get(context, "lvrByProjectTokens", EMPTY_OBJECT);
  const currentLimitPrjToken = get(context, "currentLimitPrjToken", EMPTY_OBJECT);
  const pitCollateralBalance = get(context, "pitCollateralBalance", EMPTY_OBJECT);
  const pitRemaining = get(context, "pitRemaining", EMPTY_OBJECT);
  const totalOutstandingAssets = get(context, "totalOutstandingAssets", EMPTY_OBJECT);
  const accrualAssets = get(context, "accrualAssets", EMPTY_OBJECT);
  const healthFactor = get(context, "healthFactor", EMPTY_OBJECT);
  const depositedAssets = get(context, "depositedAssets", EMPTY_OBJECT);
  const liquidationThresholdList = get(context, "liquidationThresholdList", EMPTY_ARRAY);
  const priceCollateralTokens = get(context, "priceOfTokens", {});
  const evaluations = get(context, "evaluations", EMPTY_OBJECT);

  const lending = useMemo(() => {
    if (!isFetched) return DEFAULT_CONTEXT.lendingList;

    const lendingTokens = get(context, "availableBorrowTokens", []);

    const currentLimitLendingToken = get(context, "currentLimitLendingToken", EMPTY_OBJECT);
    const fTokenAddresses = get(context, "fTokenAddresses", EMPTY_ARRAY);

    const fTokenRate = get(context, "fTokenRate", EMPTY_OBJECT);
    const allowanceForFToken = get(context, "allowanceForFToken", EMPTY_OBJECT);
    const cashFToken = get(context, "cashFToken", EMPTY_OBJECT);
    const lvrLending = get(context, "lvrLending", EMPTY_OBJECT);
    const userTokenInfo = get(context, "userTokenInfo", EMPTY_ARRAY);

    return Array.isArray(lendingTokens)
      ? lendingTokens.map((token) => ({
          ...token,
          ...currentLimitLendingToken[token.address],
          ...userTokenInfo.find((o) => o.address === token.address),
          type: "lending",
          decimal: decimalToken[token.address],
          fLendingToken: fTokenAddresses.find((o) => o.token === token.address && !o.isPaused)
            ?.ftoken,
          supplyApy: fTokenRate[token.address]?.lenderApy || "0",
          supplyRate: fTokenRate[token.address]?.lenderRate || "0",
          borrowApy: fTokenRate[token.address]?.borrowApy || "0",
          borrowRate: fTokenRate[token.address]?.borrowRate || "0",
          isAllowFToken: allowanceForFToken[token.address]?.allowance || false,
          totalAvailableBorrow: cashFToken[token.address]?.cash || "0",
          lvr: lvrLending[token.address] || 0,
          evaluation: formatUnits(evaluations[token.address], USD_DECIMAL),
          price: get(priceCollateralTokens, token.address),
        }))
      : EMPTY_ARRAY;
  }, [isFetched, context, decimalToken, evaluations, priceCollateralTokens]);

  const lendingTokensObj = groupBy(lending, "address");

  const collateral = useMemo(
    () =>
      [...collateralTokens].map((token) => {
        const tokenDecimal = decimalToken[token.address];

        const liquidationThresholdBN = [...liquidationThresholdList]
          .filter((o) => o.collateralToken === token.address)
          .reduce((acc, cur) => new BigNumber(cur?.value).plus(acc).toString(), "0");
        const lendingTokenAddress = totalOutstandingAssets[token.address]?.lendingToken;
        const lendingTokenDecimal = decimalToken[lendingTokenAddress];
        const underlyingTokens = get(token, "underlyingTokens", []);

        const lpSymbol = `${get(underlyingTokens, [0, "symbol"])}/${get(underlyingTokens, [
          1,
          "symbol",
        ])}`;

        if (underlyingTokens.length > 1) {
          token.symbol = lpSymbol;
        }

        return {
          ...token,
          ...currentLimitPrjToken[token.address],
          type: "collateral",
          decimal: tokenDecimal,
          lvr: lvrCollateralTokens[token.address] || 0,
          price: priceCollateralTokens[token.address],
          healthFactor: healthFactor[token.address],
          lendingToken: lendingTokenAddress,
          lendingTokenData: get(lendingTokensObj, [lendingTokenAddress, 0]),
          listPitRemaining: pitRemaining,
          pitRemaining: formatUnits(
            get(pitRemaining, [token.address, lendingTokenAddress], "0"),
            decimalToken[lendingTokenAddress] || 18
          ),
          pitRemainingBN: get(pitRemaining, [token.address, lendingTokenAddress], "0"),
          pitCollateral: formatUnits(pitCollateralBalance[token.address] || "0", USD_DECIMAL),
          pitCollateralBN: pitCollateralBalance[token.address] || "0",
          outstanding: formatUnits(
            totalOutstandingAssets[token.address]?.value || "0",
            lendingTokenDecimal
          ),
          outstandingBN: totalOutstandingAssets[token.address]?.value,
          loanBody: formatUnits(
            totalOutstandingAssets[token.address]?.loanBody || "0",
            lendingTokenDecimal
          ),
          loanBodyBN: totalOutstandingAssets[token.address]?.loanBody,
          accrual: formatUnits(accrualAssets[token.address]?.value || "0", lendingTokenDecimal),
          accrualBN: accrualAssets[token.address]?.value,
          depositedAmount: formatUnits(depositedAssets[token.address]?.value || "0", tokenDecimal),
          depositedAmountBN: depositedAssets[token.address]?.value,
          liquidationThreshold: formatUnits(liquidationThresholdBN, tokenDecimal),
          liquidationThresholdBN,
          evaluation: formatUnits(get(evaluations, [token.address]), USD_DECIMAL),
        };
      }),
    [
      accrualAssets,
      collateralTokens,
      currentLimitPrjToken,
      decimalToken,
      depositedAssets,
      evaluations,
      healthFactor,
      lendingTokensObj,
      liquidationThresholdList,
      lvrCollateralTokens,
      pitCollateralBalance,
      pitRemaining,
      priceCollateralTokens,
      totalOutstandingAssets,
    ]
  );

  const collateralTokenObj = useMemo(() => groupBy(collateral, "address"), [collateral]);

  if (!context?.isFetched) return DEFAULT_CONTEXT;

  return {
    collateralTokenObj,
    lendingTokensObj,
    collateralList: collateral,
    lendingList: lending,
  };
};

export default useAllBorrowData;
