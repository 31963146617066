import { constants, providers } from "ethers";
import {
  arbitrumGoerli,
  goerli,
  optimismGoerli,
  polygonMumbai,
  mainnet,
  polygon,
  arbitrum,
  optimism,
  zkSyncTestnet,
  zkSync,
} from "wagmi/chains";
import { REACT_APP_WRAP_NATIVE_TOKEN } from "constants/NetworkChainId";
import ethIcon from "assets/icons/eth.png";
import { getLogoBySymbolAndName } from "utils/utils";
import { NATIVE_TOKEN } from "app/constants";

export const isEqualLowerString = (ad1 = constants.AddressZero, ad2 = constants.AddressZero) =>
  ad1.toLowerCase() === ad2.toLowerCase();

export const isZksyncNetwork = (chainId) => chainId === process.env.REACT_APP_CHAIN_ID_ZKSYNC;
export const isSupportZkSync = () => process.env.REACT_APP_SUPPORT_ZKSYNC === "true";

export const isIncludeNetwork = {
  polygon: process.env.REACT_APP_INCLUDE_POLYGON === "true",
  ethereum: process.env.REACT_APP_INCLUDE_ETHEREUM === "true",
  arbitrum: process.env.REACT_APP_INCLUDE_ARBITRUM === "true",
  optimism: process.env.REACT_APP_INCLUDE_OPTIMISM === "true",
  zkSync: process.env.REACT_APP_INCLUDE_ZKSYNC === "true",
};

const isIncludeNetworkArray = [
  {
    name: "polygon",
    isOn: process.env.REACT_APP_INCLUDE_POLYGON === "true",
    mainnet: polygon,
    testnet: polygonMumbai,
  },
  {
    name: "ethereum",
    isOn: process.env.REACT_APP_INCLUDE_ETHEREUM === "true",
    mainnet,
    testnet: goerli,
  },
  {
    name: "arbitrum",
    isOn: process.env.REACT_APP_INCLUDE_ARBITRUM === "true",
    mainnet: arbitrum,
    testnet: arbitrumGoerli,
  },
  {
    name: "optimism",
    isOn: process.env.REACT_APP_INCLUDE_OPTIMISM === "true",
    mainnet: optimism,
    testnet: optimismGoerli,
  },
  {
    name: "zkSync",
    isOn: process.env.REACT_APP_INCLUDE_ZKSYNC === "true",
    mainnet: zkSync,
    testnet: zkSyncTestnet,
  },
];

export const TestNetwork = isIncludeNetworkArray
  .filter((network) => network.isOn)
  .map((network) => network.testnet);

export const MainNetwork = isIncludeNetworkArray
  .filter((network) => network.isOn)
  .map((network) => network.mainnet);

export const MainNetworkSupported = [1, 137, 42161, 10, 324];
export const ZERO_ADDRESS = "0x0000000000000000000000000000000000000000";

export const isMainNetWork = (chainId) => MainNetworkSupported.includes(+chainId);

const getDefaultNetwork = () => {
  const networks = process.env.REACT_APP_NETWORK === "mainnet" ? MainNetwork : TestNetwork;
  const defaultNetworkId = process.env.REACT_APP_DEFAULT_CHAIN_ID;
  const defaultNetwork = networks.find(
    (network) => defaultNetworkId === `0x${network.id.toString(16)}`
  );

  if (!defaultNetwork) {
    return process.env.REACT_APP_NETWORK === "mainnet" ? mainnet : goerli;
  }
  return defaultNetwork;
};

export const defaultNetwork = getDefaultNetwork();
export const defaultProvider = new providers.JsonRpcProvider(
  process.env.REACT_APP_DEFAULT_RPC_URL || defaultNetwork.rpcUrls.default.http[0]
);

export const wrapNativeToken = (chainId) => {
  const defaultNetworkId = `0x${defaultNetwork.id.toString(16)}`;
  const address = REACT_APP_WRAP_NATIVE_TOKEN[chainId || defaultNetworkId];
  if (!address) return {};
  const { logo, name, symbol } = NATIVE_TOKEN[chainId || defaultNetworkId] || {};
  return {
    address,
    name,
    symbol,
    logo: logo || getLogoBySymbolAndName(symbol, name, address) || ethIcon,
  };
};

export const isWrapNative = (address, chainId) => {
  const { address: nativeAddress } = wrapNativeToken(chainId);
  return address.toLowerCase() === nativeAddress?.toLowerCase();
};
