import { constants, Contract, ethers } from "ethers";

import { isEqualLowerString, isZksyncNetwork, MainNetworkSupported } from "utils/addressUtils";
import { formatUnits } from "utils/number";
import uniSwapV2PairARTIFACT from "./json/UniswapV2Pair.json";
import paraSwapAdapterARTIFACT from "./json/NewUniswapV2Router.json";
import { getUniswapV2Factory } from "./contracts";
import { createCallDataParaswap } from "./createCallDataParaswap";
import { getDataFromOpenOcean } from "./getDataFromOpenOcean";

async function createSellCallDataOnTestNet(
  tokenCollateral,
  amountIn,
  amountOutMin,
  weth,
  lendingToken,
  { chainId, signer }
) {
  const usdcToken = localStorage.getItem("usdcToken");
  const poolsList = [];
  const uniswapInfo = getUniswapV2Factory(chainId);
  const uniswapContract = new Contract(uniswapInfo?.address, uniswapInfo?.abi, signer);
  let pools = [];

  if (isEqualLowerString(lendingToken, usdcToken)) {
    const pairs2 = await uniswapContract.getPair(tokenCollateral, usdcToken);
    pools = [pairs2];
  } else if (!usdcToken) {
    const pairs1 = await uniswapContract.getPair(lendingToken, tokenCollateral);
    pools = [pairs1];
  } else {
    const pairs2 = await uniswapContract.getPair(tokenCollateral, usdcToken);
    const pairs1 = await uniswapContract.getPair(lendingToken, usdcToken);
    pools = [pairs1, pairs2];
  }

  let tokenInNext;
  await Promise.all(
    pools.map(async (o, i) => {
      const pairToken = new ethers.Contract(o, uniSwapV2PairARTIFACT, signer);
      const token0 = await pairToken.token0();
      const token1 = await pairToken.token1();

      let prefix;
      if (i === 0) {
        if (isEqualLowerString(lendingToken, token0)) {
          prefix = "4de4";
          tokenInNext = token1;
        } else {
          prefix = "4de5";
          tokenInNext = token0;
        }
      } else if (isEqualLowerString(tokenInNext, token0)) {
        prefix = "4de4";
        tokenInNext = token1;
      } else {
        prefix = "4de5";
        tokenInNext = token0;
      }
      const convertedPool = pools[i].slice(0, 2) + prefix + pools[i].slice(2);

      poolsList.push(convertedPool);
    })
  );

  const iface = new ethers.utils.Interface(paraSwapAdapterARTIFACT);

  const result = iface.encodeFunctionData("swapOnUniswapV2Fork", [
    lendingToken,
    amountIn,
    amountOutMin,
    weth,
    poolsList,
  ]);

  return result;
}

export const createSellCallData = async ({
  tokenCollateral,
  amountIn,
  amountOutMin,
  lendingToken = "",
  chainId,
  account,
  signer,
}) => {
  if (MainNetworkSupported.includes(+chainId)) {
    const isZkSync = isZksyncNetwork(chainId);

    if (isZkSync) {
      const { data } = await getDataFromOpenOcean(
        lendingToken.address,
        tokenCollateral.address,
        chainId,
        formatUnits(amountIn, lendingToken.decimal || 18),
        signer,
        account
      );

      return data.data; // get data field of OpenOcean
    }

    return createCallDataParaswap(
      lendingToken.address,
      lendingToken.decimal,
      tokenCollateral.address,
      tokenCollateral.decimal,
      amountIn,
      "SELL",
      {
        chainId,
        account,
      }
    );
  }
  return createSellCallDataOnTestNet(
    tokenCollateral.address,
    amountIn,
    amountOutMin,
    constants.AddressZero,
    lendingToken.address,
    {
      chainId,
      account,
      signer,
    }
  );
};
