/* eslint-disable no-await-in-loop */
import { EvmPriceServiceConnection } from "@pythnetwork/pyth-evm-js";
import _ from "lodash";

const connection = new EvmPriceServiceConnection("https://hermes.pyth.network");

export async function getPriceFeedsUpdateData(priceIds) {
  const updateData = await connection.getPriceFeedsUpdateData(priceIds);

  return updateData;
}

export async function handleGetExpiredPriceFeed(resultPriceAggregatorFromMultiCall) {
  const PriceAggregatorContract = _.get(
    resultPriceAggregatorFromMultiCall,
    "PriceAggregatorContract"
  );

  const dataOnPriceAggregatorContract = _.get(PriceAggregatorContract, ["callsReturnContext"], []);

  const listExpiredPriceFeeds = {
    prjToken: {},
    lendingToken: {},
  };

  for (let index = 0; index < dataOnPriceAggregatorContract.length; index += 1) {
    const element = dataOnPriceAggregatorContract[index];
    const onlyPrjToken = _.get(element, ["reference"], "").toString().startsWith("prjToken");
    const onlyLendingToken = _.get(element, ["reference"], "")
      .toString()
      .startsWith("lendingToken");

    const tokenParam = _.get(element, ["methodParameters", 0], []);
    const priceIds = _.get(element, ["returnValues", 0], []);
    const updateFee = _.get(element, ["returnValues", 1], 0);

    if (!listExpiredPriceFeeds[tokenParam[0]] && !onlyPrjToken && !onlyLendingToken) {
      listExpiredPriceFeeds[tokenParam[0]] = {};
    }

    let updateData = [];
    if (priceIds.length > 0) {
      updateData = await getPriceFeedsUpdateData(priceIds);
    }

    const value = {
      priceIds,
      updateFee,
      updateData,
    };

    if (onlyPrjToken) {
      listExpiredPriceFeeds.prjToken = {
        ...listExpiredPriceFeeds.prjToken,
        [tokenParam[0]]: value,
      };
    } else if (onlyLendingToken) {
      listExpiredPriceFeeds.lendingToken = {
        ...listExpiredPriceFeeds.lendingToken,
        [tokenParam[0]]: value,
      };
    } else {
      listExpiredPriceFeeds[tokenParam[0]][tokenParam[1]] = value;
    }
  }

  return listExpiredPriceFeeds;
}
