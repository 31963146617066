import { Contract } from "ethers";
import { formatUnits } from "utils/number";
import { useWallet } from "hooks";
import { useQuery } from "react-query";

import { LENDING_TOKEN_ADDRESS } from "constants/NetworkChainId";
import { ERC20TokenABI } from "utils/ethereum/abi";
import { useChainId } from "wagmi";

const useAccountBalance = () => {
  const { account, signer } = useWallet();
  const chainId = useChainId();

  return useQuery(["account-balance", account, chainId], async () => {
    try {
      const connected = Boolean(account);

      if (connected) {
        const contract = new Contract(LENDING_TOKEN_ADDRESS[this.chainId], ERC20TokenABI, signer);

        const [balance, symbol] = await Promise.all([
          contract.balanceOf(this.account),
          contract.symbol(),
        ]);

        const decimals = await contract.decimals();

        const result = {
          balance: formatUnits(balance, decimals),
          symbol,
        };

        return [result];
      }

      return [
        {
          balance: 0,
          symbol: "USDCTest",
        },
      ];
    } catch (error) {
      return [
        {
          balance: 0,
          symbol: "USDCTest",
        },
      ];
    }
  });
};

export default useAccountBalance;
