import { usePITContractAction } from "hooks/contract/core/usePITContractAction";
import { usePriceContract } from "hooks/contract/core/usePriceContract";
import useWallet from "hooks/useWallet";
import { methodOfPitContractZksync } from "pages/BorrowerNewDashboard/hooks/PITContract/methodOfPitContract";
import { useQuery } from "react-query";
import { getDataToUpdatePrice } from "utils/ethereum/getDataToUpdatePrice";

const { getCollateralAvailableToWithdrawWithUpdatePricesMethod } = methodOfPitContractZksync;

export const useGetAvailableToWithdraw = (prjToken, lendingToken) => {
  const { account, chainId } = useWallet();
  const { PITContract } = usePITContractAction();
  const { PriceContract } = usePriceContract();

  const availableToWithdraw = useQuery(
    ["available-to-withdraw", account, chainId, prjToken, lendingToken],
    async () => {
      const { priceIds, payableAmount, updateData } = await getDataToUpdatePrice(
        [prjToken, lendingToken],
        PriceContract
      );

      const res = await PITContract.callStatic[
        getCollateralAvailableToWithdrawWithUpdatePricesMethod
      ](account, prjToken, lendingToken, priceIds, updateData, {
        value: payableAmount,
      });

      return res;
    }
  );

  return availableToWithdraw.data;
};
