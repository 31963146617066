/* eslint-disable no-await-in-loop */
import { BigNumber, Contract } from "ethers";
import { getDataToUpdatePrice } from "utils/ethereum/getDataToUpdatePrice";
import { parseUnits } from "utils/number";
import { MainNetworkSupported } from "utils/addressUtils";
import methodOnPITContract, { methodOfPitContractZksync } from "./methodOfPitContract";

const {
  limitBorrowInCollateralMethod,
  limitBorrowInLendingTokenMethod,
  lendingTokenInfoMethod,
  getLendingTokenMethod,
} = methodOnPITContract;

const {
  getLendingAvailableToBorrowWithUpdatePricesMethod,
  pitCollateral,

  getTokenEvaluationWithUpdatePricesMethod,
  getPosition,
  pitRemaining,
  getTotalBorrowPerLendingToken,
} = methodOfPitContractZksync;

// fetch batch with contraints group
export async function prepareRequestMulticallZksync({
  PITToken,
  listToken: collateralTokens,
  availableBorrowTokens: lendingTokenList,
  signerOrProvider,
  account,
  decimalTokens,
  PriceContractInfo,
  chainId,
  UniswapV2FactoryInfo,
}) {
  const usdcToken = lendingTokenList.find((o) => o.symbol === "USDC" || o.symbol === "USDC.e");
  const usdcAddress = usdcToken?.address || "";

  const PITContract = new Contract(PITToken.address, PITToken.abi, signerOrProvider);
  const PriceContract = new Contract(
    PriceContractInfo.address,
    PriceContractInfo.abi,
    signerOrProvider
  );

  const listRequestToPITContract = [];
  const listRequestToUniswapV2FactoryContract = [];

  const UniswapV2FactoryContract = new Contract(
    UniswapV2FactoryInfo.address,
    UniswapV2FactoryInfo.abi,
    signerOrProvider
  );

  const tokenAddressList = Array.from(
    // reduce duplicate
    new Set([...collateralTokens, ...lendingTokenList].map((o) => o.address))
  );

  const { payableAmount, updateData, priceIds } = await getDataToUpdatePrice(
    tokenAddressList,
    PriceContract
  );

  collateralTokens.forEach((prjToken) => {
    listRequestToPITContract.push([
      {
        target: PITToken.address,
        callData: PITContract.interface.encodeFunctionData(
          getTokenEvaluationWithUpdatePricesMethod,
          [
            prjToken.address,
            parseUnits("1.0", decimalTokens[prjToken.address]),
            priceIds,
            updateData,
          ]
        ),

        value: payableAmount,
        methodParameters: [
          prjToken.address,
          parseUnits("1.0", decimalTokens[prjToken.address]),
          priceIds,
          updateData,
        ],
        methodName: getTokenEvaluationWithUpdatePricesMethod,
        reference: prjToken.address,
        contract: PITContract,
        contractName: "PITContract",
      },

      {
        target: PITToken.address,
        callData: PITContract.interface.encodeFunctionData(limitBorrowInCollateralMethod, [
          prjToken.address,
        ]),
        methodParameters: [prjToken.address],
        methodName: limitBorrowInCollateralMethod,
        reference: prjToken.address,
        contract: PITContract,
        contractName: "PITContract",
        value: BigNumber.from(0),
      },

      {
        target: PITToken.address,
        callData: PITContract.interface.encodeFunctionData(pitCollateral, [
          account,
          prjToken.address,
        ]),
        value: BigNumber.from(0),
        methodParameters: [account, prjToken.address],
        methodName: pitCollateral,
        reference: prjToken.address,
        contract: PITContract,
        contractName: "PITContract",
      },
      {
        target: PITToken.address,
        callData: PITContract.interface.encodeFunctionData(getLendingTokenMethod, [
          account,
          prjToken.address,
        ]),
        methodParameters: [account, prjToken.address],
        methodName: getLendingTokenMethod,
        reference: prjToken.symbol,
        contract: PITContract,
        contractName: "PITContract",
        value: BigNumber.from(0),
      },
      {
        target: PITToken.address,
        callData: PITContract.interface.encodeFunctionData(getLendingTokenMethod, [
          account,
          prjToken.address,
        ]),
        methodParameters: [account, prjToken.address],
        methodName: getLendingTokenMethod,
        reference: prjToken.symbol,
        contract: PITContract,
        contractName: "PITContract",
        value: BigNumber.from(0),
      },
    ]);

    lendingTokenList.forEach((lendingToken) => {
      listRequestToPITContract.push([
        {
          target: PITToken.address,
          callData: PITContract.interface.encodeFunctionData(
            getLendingAvailableToBorrowWithUpdatePricesMethod,
            [account, prjToken.address, lendingToken.address, priceIds, updateData]
          ),
          value: payableAmount,
          methodParameters: [account, prjToken.address, lendingToken.address, priceIds, updateData],
          methodName: getLendingAvailableToBorrowWithUpdatePricesMethod,
          reference: lendingToken.symbol,
          contract: PITContract,
          contractName: "PITContract",
        },
        {
          target: PITToken.address,
          callData: PITContract.interface.encodeFunctionData(getPosition, [
            account,
            prjToken.address,
            lendingToken.address,
          ]),

          value: BigNumber.from(0),
          methodParameters: [account, prjToken.address, lendingToken.address],
          methodName: getPosition,
          reference: lendingToken.symbol,
          contract: PITContract,
          contractName: "PITContract",
        },
        {
          target: PITToken.address,
          callData: PITContract.interface.encodeFunctionData(pitRemaining, [
            account,
            prjToken.address,
            lendingToken.address,
          ]),

          value: BigNumber.from(0),
          methodParameters: [account, prjToken.address, lendingToken.address],
          methodName: pitRemaining,
          reference: lendingToken.symbol,
          contract: PITContract,
          contractName: "PITContract",
        },
      ]);
    });
  });

  lendingTokenList.forEach((lending) => {
    listRequestToPITContract.push([
      {
        target: PITToken.address,
        callData: PITContract.interface.encodeFunctionData(
          getTokenEvaluationWithUpdatePricesMethod,
          [lending.address, parseUnits("1.0", decimalTokens[lending.address]), priceIds, updateData]
        ),
        value: payableAmount,
        methodParameters: [
          lending.address,
          parseUnits("1.0", decimalTokens[lending.address]),
          priceIds,
          updateData,
        ],
        methodName: getTokenEvaluationWithUpdatePricesMethod,
        reference: lending.address,
        contract: PITContract,
        contractName: "PITContract",
      },

      {
        target: PITToken.address,
        callData: PITContract.interface.encodeFunctionData(limitBorrowInLendingTokenMethod, [
          lending.address,
        ]),
        methodParameters: [lending.address],
        methodName: limitBorrowInLendingTokenMethod,
        reference: lending.address,
        contract: PITContract,
        contractName: "PITContract",
        value: BigNumber.from(0),
      },
      {
        target: PITToken.address,
        callData: PITContract.interface.encodeFunctionData(lendingTokenInfoMethod, [
          lending.address,
        ]),
        methodParameters: [lending.address],
        methodName: lendingTokenInfoMethod,
        reference: lending.address,
        contract: PITContract,
        contractName: "PITContract",
        value: BigNumber.from(0),
      },
      {
        target: PITToken.address,
        callData: PITContract.interface.encodeFunctionData(getTotalBorrowPerLendingToken, [
          lending.address,
        ]),
        value: BigNumber.from(0),
        methodParameters: [lending.address],
        methodName: getTotalBorrowPerLendingToken,
        reference: lending.address,
        contract: PITContract,
        contractName: "PITContract",
      },
    ]);

    if (!MainNetworkSupported.includes(+chainId)) {
      listRequestToUniswapV2FactoryContract.push([
        {
          target: UniswapV2FactoryInfo.address,
          callData: UniswapV2FactoryContract.interface.encodeFunctionData("getPair", [
            lending.address,
            usdcAddress,
          ]),
          contract: UniswapV2FactoryContract,
          contractName: "UniswapV2FactoryContract",
          reference: `${usdcAddress}`,
          methodName: "getPair",
          methodParameters: [lending.address, usdcAddress],
          value: BigNumber.from(0),
        },
      ]);
    }
  });

  return [...listRequestToPITContract, ...listRequestToUniswapV2FactoryContract];
}
