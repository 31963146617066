import useWallet from "hooks/useWallet";
import { useCallback, useMemo } from "react";
import { ZERO_ADDRESS, isEqualLowerString } from "utils/addressUtils";
import {
  getPriceProviderAggregatorContract,
  getPrimaryIndexToken,
  getPythProvider,
} from "utils/ethereum/contracts";
import { getDataToUpdatePrice } from "utils/ethereum/getDataToUpdatePrice";
import { useContract } from "wagmi";
import { BigNumber } from "ethers";
import handleGetListTokenProvider from "utils/ethereum/handleGetListTokenProvider";
import { useBorrowContext } from "context/contracts/BorrowContext";
import * as _ from "lodash";
import { getTotalBorrow } from "utils/ethereum/getTotalBorrow";
import { REACT_APP_ACCOUNT_HAVING_ETH } from "constants/NetworkChainId";
import { usePriceContract } from "./core/usePriceContract";
import { useMultiCallContractInstance } from "./multicall/useMultiCallContract";

export const usePITContract = () => {
  const { chainId, signer, provider } = useWallet();
  const PitInstance = useMemo(() => getPrimaryIndexToken(chainId), [chainId]);

  const context = useBorrowContext();
  const lendingTokenList = _.get(context, "availableBorrowTokens", []);

  const { PriceContract } = usePriceContract();
  const PriceContractInfo = getPriceProviderAggregatorContract(chainId);
  const multicallContract = useMultiCallContractInstance();

  const contract = useContract({
    address: PitInstance?.address,
    abi: PitInstance?.abi,
    signerOrProvider: signer,
  });

  const pitContractRO = useContract({
    address: PitInstance?.address,
    abi: PitInstance?.abi,
    signerOrProvider: provider,
  });

  const pythAddress = getPythProvider(chainId).address;

  const depositCall = useCallback(
    async ({ collateralAddress, collateralAmount }) => {
      if (!contract) return;

      const callTx = await contract.deposit(collateralAddress, collateralAmount);

      await callTx.wait();
    },
    [contract]
  );

  const withdrawCall = useCallback(
    async ({ collateralAddress, collateralAmount, lendingAddress }) => {
      const noLending = isEqualLowerString(lendingAddress, ZERO_ADDRESS);

      if (!contract) return;

      const tokenToGetUpdatePrice = noLending
        ? [collateralAddress]
        : [collateralAddress, lendingAddress];

      if (!PriceContract) return;
      const { priceIds, payableAmount, updateData } = await getDataToUpdatePrice(
        tokenToGetUpdatePrice,
        PriceContract
      );

      const callTx = await contract.withdraw(
        collateralAddress,
        collateralAmount,
        priceIds,
        updateData,
        {
          value: payableAmount,
        }
      );

      await callTx.wait();
    },
    [contract, PriceContract]
  );

  const supplyCall = useCallback(
    async ({ lendingTokenAddress, lendingTokenAmount }) => {
      if (!contract) return;

      const callTx = await contract.supply(lendingTokenAddress, lendingTokenAmount);

      await callTx.wait();
    },
    [contract]
  );

  const redeemUnderlyingCall = useCallback(
    async ({ lendingTokenAddress, lendingTokenAmount }) => {
      if (!contract) return;

      const callTx = await contract.redeemUnderlying(lendingTokenAddress, lendingTokenAmount);

      await callTx.wait();
    },
    [contract]
  );

  const borrowCall = useCallback(
    async ({ collateral, lendingToken, lendingAmount }) => {
      if (!contract) return;

      if (!PriceContract) return;

      const listGetTotalBorrow = [];
      lendingTokenList
        .filter((lending) => lending.address !== lendingToken)
        .forEach((lending) => {
          listGetTotalBorrow.push(getTotalBorrow(collateral, lending.address, contract));
        });

      const tokenAmountList = await Promise.all(listGetTotalBorrow);
      const listToUpdatePrice = [];

      tokenAmountList.forEach((token) => {
        if (+token.totalBorrow > 0) listToUpdatePrice.push(token.lendingTokenAddress);
      });

      const { priceIds, payableAmount, updateData } = await getDataToUpdatePrice(
        [...listToUpdatePrice, collateral, lendingToken],
        PriceContract
      );

      const callTx = await contract.borrow(
        collateral,
        lendingToken,
        lendingAmount,
        priceIds,
        updateData,
        {
          value: payableAmount,
        }
      );

      await callTx.wait();
    },
    [PriceContract, contract, lendingTokenList]
  );

  const evaluationCall = useCallback(
    async ({ address, amount }) => {
      if (!contract && !PriceContract && !pitContractRO) return BigNumber.from(0);

      const priceProviderList = await handleGetListTokenProvider(
        [{ address }],
        PriceContractInfo,
        provider,
        multicallContract,
        chainId
      );

      const tokenPriceProvider = priceProviderList[address];

      const isUsingPyth = pythAddress.toString() === tokenPriceProvider.toString();

      let payableAmount = BigNumber.from(0);
      let updateData = [];
      let priceIds = [];

      if (isUsingPyth) {
        const dataUpdatePrice = await getDataToUpdatePrice([address], PriceContract);

        payableAmount = dataUpdatePrice.payableAmount;
        updateData = dataUpdatePrice.updateData;
        priceIds = dataUpdatePrice.priceIds;
      }

      const evaluation = await pitContractRO.callStatic.getTokenEvaluationWithUpdatePrices(
        address,
        amount,
        priceIds,
        updateData,
        { value: payableAmount, from: REACT_APP_ACCOUNT_HAVING_ETH[chainId] }
      );

      return evaluation;
    },
    [
      contract,
      PriceContract,
      PriceContractInfo,
      provider,
      multicallContract,
      pythAddress,
      chainId,
      pitContractRO,
    ]
  );

  return {
    callback: {
      depositCall,
      withdrawCall,
      supplyCall,
      redeemUnderlyingCall,
      borrowCall,
      evaluationCall,
    },
    data: {
      PitInstance,
      PitContract: contract,
    },
  };
};
