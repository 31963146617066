import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  rootContainer: {
    minWidth: 400,
    color: theme.palette.primary.main,
    paddingLeft: 0,
    paddingRight: 0,
    width: 429,

    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      minWidth: "100%",
      height: "100%",
      width: "100%",
    },
  },

  contentInner: {
    position: "relative",
    backgroundColor: "#F8F8F8",
    [theme.breakpoints.down("sm")]: {
      flex: 1,
    },
  },
  supplyBox: {
    borderBottom: "1px solid #E0E0E0",
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  supplyRates: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: "18px",
  },
  maxRepay: {
    marginTop: theme.spacing(2),
  },
  maxButton: {
    borderLeft: `1px solid #ccc`,
    color: "#fff",
    width: "100%",
    height: "100%",
    backgroundColor: "#27AE60",
    borderRadius: 0,
    "&:hover": {
      backgroundColor: "transparent",
      color: theme.palette.success.main,
      border: `1px solid #ccc`,
    },
  },

  collateralAmount: {
    padding: "0 16px",
    paddingBottom: 16,
  },
}));
